import React, { useContext, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { IoNotificationsSharp } from "react-icons/io5";
import Profile from "../../../images/topProfile.png";
import { getCookie, isAuth } from "../../../auth/helper";
import { Link } from "react-router-dom";
import GlobalStorage from "../../../Storage/ContextProvider";
import BASE_URL from "../../../baseUrl";
import axios from "axios";

const Topbar = () => {
  const [showNotification, setShowNotification] = useState(false);
  const { doctorProfile, setDoctorProfile } = useContext(GlobalStorage);
  const [notifications, setNotifications] = useState([]);
  const token = getCookie("token");

  const loadNotifications = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/notifications`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("Get notifications", response);
        setNotifications(response.data);
      })
      .catch((error) => {
        console.log("Get notification ERROR", error.response);
      });
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  return (
    <div className="h-full flex items-center justify-between  ">
      <div className="flex items-center w-[600px] h-[45px] dark:bg-[#67A599] bg-[var(--doctor-color)] rounded-full relative text-[#fff] ml-[20px] ">
        <CiSearch className="absolute left-[10px]" />
        <input
          className="bg-transparent w-full outline-none ml-[35px] mr-[10px] placeholder-white"
          type="text"
          placeholder="Search Dashboard"
        />
      </div>
      <div className="mr-[50px] flex gap-[30px]">
        <div
          className="relative flex justify-center items-center h-[40px] w-[40px] rounded-full dark:bg-[rgba(244,245,249,1)] bg-[var(--doctor-color)] cursor-pointer"
          onClick={() => setShowNotification(!showNotification)}
        >
          <IoNotificationsSharp className="dark:text-[var(--doctor-color)] text-[#fff] w-[16px] h-[16px]" />
          {showNotification && (
            <>
              <div className="absolute top-[60px] -right-[15px] w-[272px] max-h-[312px] bg-[#fff] z-[101] rounded-[11px]">
                <div className=" notyNotch absolute right-[20px] -top-[25px] "></div>
                <div>
                  {notifications?.map((item) => (
                    <div className="border-b-[1px] border-[rgba(119, 119, 119, 0.5)] mx-[15px] py-[15px] flex items-center gap-[15px]">
                      <img
                        src={Profile}
                        className="w-[44px] h-[44px] rounded-full"
                        alt=""
                      />
                      <div className="">
                        <p className="text-[13px] font-[500] text-[#373737]">
                          Doctor
                        </p>
                        <p className="text-[13px] text-[#373737]">
                          {item?.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
        <Link
          to="/doctor/setting/general"
          className="flex items-center gap-[15px] "
        >
          <img
            src={Profile}
            className="w-[40px] h-[40px] rounded-full"
            alt=""
          />
          <div>
            <p className="dark:text-[#fff] text-[#000000] text-[14px]">
              {doctorProfile?.fullName}
            </p>
            <p className="dark:text-[rgba(231,244,242,0.46)] text-[rgba(0,0,0,.42)] text-[14px]">
              {doctorProfile?.email}
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Topbar;
