import React, { useContext, useState } from "react";
import HomeHeader from "../Component/HomeHeader";
import QuestionImage from "../images/question.svg";
import MinusImg from "../images/minus.svg";
import PlusImg from "../images/plus.svg";
import Question from "../Component/reusableCompo/Question";
import HomeFooter from "../Component/HomeFooter";
import GlobalStorage from "../Storage/ContextProvider";
const faqItem = [
  {
    title: "Simplify up to 50 notes",
    desc: "Simplifying up to 50 notes means turning complex information into clear, concise summaries. This process highlights key points and reduces jargon, making the content more accessible and easier to understand. It’s a valuable tool for saving time and improving comprehension, whether for medical notes, academic research, or professional reports.",
  },
  {
    title: "Translate up to 50 notes",
    desc: "Simplifying up to 50 notes means turning complex information into clear, concise summaries. This process highlights key points and reduces jargon, making the content more accessible and easier to understand. It’s a valuable tool for saving time and improving comprehension, whether for medical notes, academic research, or professional reports.",
  },
  {
    title: "Email Support",
    desc: "For this plan, we offer email support to address your questions and issues promptly.",
  },
  {
    title: "Video tutorials",
    desc: "For this plan, we offer email support to address your questions and issues promptly.",
  },
];
const Faqs = () => {
  const [activeFaq, setActiveFaq] = useState(0);
  const [planSelected, setPlanSelected] = useState("monthly");
  const [selectedOption, setSelectedOption] = useState("free");
  const { activeMobileNav } = useContext(GlobalStorage);

  return (
    <>
      <HomeHeader activeLink="resources" />
      <div
        className={`${
          activeMobileNav ? "max-h-[calc(100vh-90px)] overflow-hidden" : ""
        }`}
      >
        <div className="px-[100px] max-[1300px]:px-[70px] max-[750px]:px-[40px]  py-[60px] font-poppins">
          <div className="flex w-full justify-between max-[1024px]:flex-col ">
            <div className="w-[35%]  max-[1024px]:w-full relative">
              <h3 className="text-[22px] leading-[33px] text-primary">FAQS</h3>
              <h2 className="text-[50px] leading-[75px]  font-semibold w-[70%]  max-[1024px]:text-[32px] max-[1024px]:leading-[57px] max-[750px]:text-[28px] max-[750px]:leading-[45px]">
                FREQUENTLY ASKED <span className="text-primary">QUESTIONS</span>
              </h2>
              <img
                src={QuestionImage}
                className="absolute max-[750px]:w-[120px] max-[750px]:h-[120px] top-[110px]  max-[1024px]:top-0 max-[1350px]:-right-[75px] right-0 w-[250px] h-[250px] max-[1024px]:right-0  max-[1024px]:w-[150px]  max-[1024px]:h-[150px]"
                alt=""
              />
            </div>

            <div className="w-[50%]  max-[1024px]:w-full">
              <div className="my-9  ">
                {faqItem?.map((item, index) => (
                  <>
                    <div className="my-11">
                      <div className="flex justify-between items-center">
                        <p className="text-[20px] leading-[30px] font-[500] text-black">
                          {item?.title}
                        </p>
                        {activeFaq === index ? (
                          <img
                            src={MinusImg}
                            className="cursor-pointer"
                            alt=""
                            onClick={() => setActiveFaq(index)}
                          />
                        ) : (
                          <img
                            src={PlusImg}
                            className="cursor-pointer"
                            alt=""
                            onClick={() => setActiveFaq(index)}
                          />
                        )}
                      </div>
                      {activeFaq === index && (
                        <>
                          <div>
                            <p className="text-sm leading-[21px] text-grey mt-6 pr-7 max-[750px]:pr-0">
                              {item?.desc}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>

          <Question />
        </div>
        <HomeFooter />
      </div>
    </>
  );
};

export default Faqs;
