import React, { useState } from "react";
import PatientDashboard from "../../../layout/patient/index";
import { CiSearch } from "react-icons/ci";
import { CgArrowsExpandRight } from "react-icons/cg";
import { Link } from "react-router-dom";

const index = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isSort, setIsSort] = useState(false);
  const rows = [
    {
      id: 1,
      title: "Ulceric Test Results",
      date: "10-October-2024",
      provider: "Dr. Titobi",
    },
    {
      id: 2,
      title: "Ulceric Test Results",
      date: "10-October-2024",
      provider: "Dr. Titobi",
    },
    {
      id: 3,
      title: "Ulceric Test Results",
      date: "10-October-2024",
      provider: "Dr. Titobi",
    },
  ];
  return (
    <PatientDashboard>
      <div className="flex justify-between items-center w-full ">
        <div className="flex items-center gap-4">
          <h2 className="text-3xl font-[500]">Reports</h2>

          <div className="flex items-center my-4  w-full h-[45px] border-[2px]  bg-[#fff] dark:bg-[var(--dark-primary)] rounded-[100px] relative text-[#000]">
            <CiSearch
              color="black"
              className="w-[24px] h-[24px]  absolute left-[10px]"
            />
            <input
              className=" w-full outline-none ml-[45px] mr-[10px] text-sm placeholder-[#7C7C7C]"
              type="text"
              placeholder="Find Report"
            />
          </div>
        </div>

        <button
          className=" px-[15px] mx-[10px] rounded-full text-white bg-white flex items-center justify-center h-[45px]"
          onClick={() => setIsSort(!isSort)}
        >
          <img
            className="w-[18px] h-[16px]"
            src={require("../../../../images/sort.png")}
            alt=""
          />
          <span className="ml-2 text-[12px] text-black font-[500]">Sort</span>
        </button>

        {isSort && (
          <>
            <div
              className="absolute  w-[258px] h-[277px] right-7 top-[200px] bg-white rounded-[20px] border-[#D7D7D7] border p-[20px]"
              style={{
                boxShadow: "0px 0px 25px 0px #00000026",
              }}
            >
              <p className="text-sm text-grey font-[500] py-3 ">Sort by</p>
              <p className="text-sm py-2">TYPE</p>
              <p className="text-sm py-2">DATE</p>
              <p className="text-sm pt-2 pb-3 border-b border-[#EFEBEB]">
                RECENT
              </p>

              <p className="text-sm py-3">ASCENDING</p>
              <p className="text-sm py-2">DESCENDING</p>
            </div>
          </>
        )}
      </div>

      <h3 className="text-lg font-[500] my-2">All files</h3>

      <div className="w-full mb-4 h-[78px] rounded-[20px] px-5 py-4 bg-white flex items-center text-[16px] font-[500]">
        <div className="w-[30%]">
          <p className="  text-[#000] text-start">Title</p>
        </div>

        <div className="w-[30%]">
          <p className="  text-[#747474] ">Date</p>
        </div>

        <div className="w-[30%] ">
          <p className="  text-[#747474]">Provider</p>
        </div>

        <div className="w-[10%]">
          <p className="  text-[#747474]"></p>
        </div>
      </div>

      <div className="w-full bg-white  rounded-[20px] font-poppins">
        {rows.map((row, index) => (
          <div
            key={row.id}
            className={`p-4  w-full text-sm font-[500] flex items-center ${
              index !== rows.length - 1 ? "border-b border-[#D7D7D7]" : ""
            }`}
          >
            <p className="w-[30%]">{row?.title}</p>
            <p className="w-[30%]">{row?.date}</p>

            <p className="w-[30%]">{row?.provider}</p>
            <Link
              to={`/v2/patient/report/${row?.id}`}
              className="w-[10%] flex items-center gap-3 cursor-pointer"
            >
              <div className="w-[24px] h-[24px] rounded-full border-[2px] border-grey flex items-center justify-center">
                <CgArrowsExpandRight className="text-grey" />
              </div>
              <p className="text-sm font-[500] text-black">View</p>
            </Link>
          </div>
        ))}
      </div>
    </PatientDashboard>
  );
};

export default index;
