import React, { useEffect, useState } from "react";
import Zig from "../../../images/landing/zihzag.png";
import { FaArrowDownLong } from "react-icons/fa6";
import MedBot from "../../../images/landing/medbot.svg";
import { CiMicrophoneOn } from "react-icons/ci";
import BASE_URL from "../../../baseUrl";
import axios from "axios";
import RequestDemo from "../RequestDemo";

const Demo = () => {
  const [rawInput, setRawInput] = useState("");
  const [processedData, setProcessedData] = useState("");
  const [rawData, setRawData] = useState("");
  const [ip, setIp] = useState("");

  //   useEffect(() => {
  //   const getLocalIP = () => {
  //     const pc = new RTCPeerConnection();
  //     pc.createDataChannel("");
  //     pc.createOffer()
  //       .then((offer) => pc.setLocalDescription(offer))
  //       .catch(console.error);

  //     pc.onicecandidate = (event) => {
  //       if (event && event.candidate) {
  //         const candidate = event.candidate.candidate;
  //         const result = candidate.match(
  //           /([0-9]{1,3}\.){3}[0-9]{1,3}/
  //         );
  //         if (result) {
  //           setIp(result[0]);
  //           pc.close();
  //         }
  //       }
  //     };
  //   };

  //   getLocalIP();
  // }, []);

  // console.log("IP", ip);

  const handleTranscrbe = async () => {
    const data = {
      medTextReport: rawInput,
    };
    setRawData(rawInput);

    const transcribe = await axios({
      method: "POST",
      url: `${BASE_URL}/transcribe-home`,
      data: data,
    })
      .then((response) => {
        console.log("Transcribe Success", response);
        setProcessedData(
          response?.data?.report?.transcribe?.replace(/\\n/g, "\n")
        );
        setRawInput("");
        // toast.success(response?.data?.message);
        // navigate("/hospital/login");
        // setDataUser(response.data.user)
      })
      .catch((error) => {
        console.log("Transcribe  Error", error.response.data);
        // toast.error(error.response.data);
      });
  };
  return (
    <>
      <div className="px-[100px] max-[1200px]:px-[70px] max-[750px]:px-[40px]  pt-[20px] pb-[30px] bg-[#2A65551A] font-poppins ">
        <div className="flex w-full max-[1024px]:flex-col  gap-12 ">
          <div className="w-[50%]  max-[1024px]:w-full">
            <div className="flex items-center relative gap-3">
              <h2 className=" text-[20px] max-[750px]:text-[18px] font-[400] leading-[30px] ">
                Have a quick tryout
              </h2>
              <div className="p-[15px] max-[750px]:p-[10px] bg-secondary rounded-full">
                <FaArrowDownLong className="text-white text-[24px] max-[750px]:text-[17px]" />
              </div>
            </div>

            <div
              className="w-full bg-primary  mt-6 rounded-[15px] p-[20px]"
              style={{ boxShadow: "0px 0px 12.4px 0px #00000026" }}
            >
              <div className="w-full flex justify-center ">
                <img src={MedBot} alt="" />
              </div>
              <div className="mt-11 h-[410px] flex flex-col justify-end ">
                <div className="bg-white  w-[340px] max-[750px]:w-[70%] max-[750px]:p-[10px] h-[49px] rounded-[15px] rounded-bl-none flex items-center justify-center my-4">
                  <p className="text-sm leading-[21px] max-[750px]:text-[13px] text-[#000]">
                    Hi, kindly enter what you would like to simplify
                  </p>
                </div>

                <div className="flex justify-end ">
                  {processedData && (
                    <div className="bg-[#E1FFF7] my-4 w-[60%] p-[15px] rounded-[15px] rounded-br-none ">
                      <p className="text-sm leading-[21px] text-[#000]">
                        {rawData}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div
                className="h-[80px] w-full rounded-[15px] border border-[#B0B0B0] mt-7 bg-white p-[10px] flex justify-between items-center gap-6"
                style={{ boxShadow: "0px 0px 12.5px 0px #00000026" }}
              >
                <textarea
                  name=""
                  id=""
                  className="outline-none w-full max-h-[60px]"
                  placeholder="Type something"
                  value={rawInput}
                  onChange={(e) => setRawInput(e.target.value)}
                ></textarea>
                {/* <button onClick={handleTranscrbe}>Send</button> */}
                <div className="w-[35px] h-[35px] rounded-full bg-primary flex items-center justify-center">
                  <CiMicrophoneOn className="text-white" size={22} />
                </div>
              </div>
            </div>
          </div>

          <div className="w-[50%]  max-[1024px]:w-full">
            <RequestDemo />
          </div>
        </div>
      </div>
    </>
  );
};

export default Demo;
