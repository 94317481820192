import React from "react";
import PartnerLogo from "../../images/landing/partnerLogo.svg";
import Logo1 from "../../images/landing/partners/grandRiver.svg";
import Logo2 from "../../images/landing/partners/pharmaShop.svg";
import Logo3 from "../../images/landing/partners/familyMedicine.svg";
import Logo4 from "../../images/landing/partners/kidsAbility.svg";
import Logo5 from "../../images/landing/partners/kitchnerDowntown.svg";

const Partner = () => {
  const logos = [Logo1, Logo2, Logo3, Logo4, Logo5];
  return (
    <>
      <div className="my-[90px] mt-[80px] w-full h-[270px] max-[750px]:h-auto px-[100px] max-[1200px]:px-[70px] max-[750px]:px-[40px] flex flex-col items-center justify-center overflow-x-hidden  font-poppins border-t border-b border-[#D7D7D7]">
        <div className="flex w-full items-center  text-black max-[750px]:flex-col ">
          <div className="border-r max-[750px]:border-r-0 max-[750px]:border-b border-[#D7D7D7] py-[30px] w-[20%] max-[1024px]:w-[30%] max-[750px]:w-full">
            <p className="text-[24px] font-[500] leading-[36px] ">
              OUR PARTNERS
            </p>
          </div>
          <div className="w-[80%] max-[1024px]:w-[70%]  pl-[60px] max-[750px]:pl-0  max-[750px]:w-full max-[750px]:pb-6 z-10 overflow-hidden">
            <div className="flex items-center logo-scroll gap-9  ">
              {/* Duplicate the logos for seamless scrolling */}
              {logos.concat(logos).map((logo, index) => (
                <img
                  key={index}
                  src={logo}
                  alt={`Partner Logo ${index}`}
                  className="logo object-contain "
                />
              ))}
            </div>
            {/* <img src={Logo1} alt="" className="max-[750px]:mb-4" />
            <img src={Logo2} alt="" className="max-[750px]:mb-4" />
            <img src={Logo3} alt="" className="max-[750px]:mb-4" />
            <img src={Logo4} alt="" className="max-[750px]:mb-4" />
            <img src={Logo5} alt="" className="max-[750px]:mb-4" /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Partner;
