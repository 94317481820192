import React, { useState } from "react";
import { FiSun } from "react-icons/fi";
import { MdOutlineDarkMode } from "react-icons/md";
import HomeCards from "../../../component/Pateint/HomeCards";
import { CiSearch } from "react-icons/ci";
import { HiArrowLongRight } from "react-icons/hi2";
import { BiEditAlt } from "react-icons/bi";
import { MdOutlineTranslate } from "react-icons/md";
import { BsUpload } from "react-icons/bs";
import { PiChatCenteredText, PiUploadFill } from "react-icons/pi";
import { CiMicrophoneOn } from "react-icons/ci";
import DoctorDashboardLayout from "../../../layout/doctor";
import SimplifyIcon from "../../../../images/simplify.svg";

const SettingsV2 = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSimplify, setIsSimplify] = useState(true);
  const [showTile, setShowTile] = useState(true);
  const [settings, setSettings] = useState({
    "in-appAssistance": true,
    transcriptionReportAlert: true,
  });

  const [settingsExternal, setSettingsExternal] = useState({
    newsletter: true,
    updates: false,
    tipsAndTutorial: true,
  });

  const handleToggle = (setting) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [setting]: !prevSettings[setting],
    }));
  };

  const handleToggleExternal = (setting) => {
    setSettingsExternal((prevSettings) => ({
      ...prevSettings,
      [setting]: !prevSettings[setting],
    }));
  };

  return (
    <DoctorDashboardLayout>
      <div className="flex items-center justify-between mb-4 ">
        <div className="flex flex-row items-center">
          <h2 className="text-3xl ml-[10px] font-[500]">Settings</h2>
        </div>
        <div className="flex flex-row">
          <div className="w-[260px] flex items-center bg-[#B0B0B0] h-[45px] rounded-full ">
            <div
              className={` ${
                isDarkMode ? "bg-[#B0B0B0] text-white" : "bg-white"
              }  w-1/2 h-[45px] flex items-center gap-1 justify-center rounded-full cursor-pointer`}
              onClick={() => setIsDarkMode(false)}
            >
              <FiSun
                className={`${!isDarkMode ? "text-grey" : "text-white"} `}
              />
              <p className="text-[13px] font-[500]">light mode</p>
            </div>
            <div
              className={` ${
                isDarkMode ? "bg-white" : "bg-[#B0B0B0] text-white"
              } w-1/2 h-[45px] flex items-center gap-1 justify-center rounded-full px-2 text-[13px] cursor-pointer`}
              onClick={() => setIsDarkMode(true)}
            >
              <MdOutlineDarkMode
                className={`${isDarkMode ? "text-grey" : "text-white"} `}
              />
              <p>dark mode</p>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4 mt-10">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {/* Left Column: Profile Editing Form */}
          <div className="p-6 bg-white border border-gray-200 rounded-[20px] shadow-sm">
            {/* Profile Picture */}
            <div className="mb-6 flex flex-row justify-between">
              <input
                type="file"
                id="automatedFile"
                style={{ display: "none" }}
                accept=".docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                //  onChange={handleChangeFile}
              />
              <button
                onClick={() => document.querySelector("#automatedFile").click()}
                // disabled={!selectPatient}
              >
                <img
                  className="h-[100px] w-[100px]"
                  src={require("../../../../images/dp.png")}
                />
              </button>
              <button>
                <div className="flex flex-row justify-center border rounded-full px-[12px] py-[8px] items-center">
                  <img
                    className="h-[24px] w-[24px]"
                    src={require("../../../../images/pencil.png")}
                  />
                  <label
                    htmlFor="profilePicture"
                    className="text-[14px] font-[400] text-gray-700"
                  >
                    Edit Profile
                  </label>
                </div>
              </button>
            </div>
            {/* Profile Form */}
            <div className="mb-6">
              <label
                htmlFor="username"
                className="block mb-2 text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <input
                type="text"
                id="username"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your username"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="username"
                className="block mb-2 text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <input
                type="text"
                id="username"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your username"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="username"
                className="block mb-2 text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <input
                type="text"
                id="username"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your username"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-700"
              >
                Work Email Address
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your email"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="username"
                className="block mb-2 text-sm font-medium text-gray-700"
              >
                Hospital Name
              </label>
              <input
                type="text"
                id="username"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your hospital name"
              />
            </div>
            <div className="text-right">
              <button
                type="submit"
                className="px-6 py-2 text-white bg-primary rounded-lg hover:bg-blue-600"
              >
                Save Changes
              </button>
            </div>
          </div>

          {/* Right Column: Settings (Checkboxes or Switches) */}
          <div className="p-6  ">
            <h1 className="text-[28px] font-[500]">Notification Preferences</h1>
            <h3 className="text-[#7C7C7C] mt-1 font-[400] text-[18px]">
              Customize your Preferences
            </h3>
            <hr className="my-8 border-[#B0B0B0] " />
            <h3 className="text-[18px] font-[500]">Internal</h3>
            <span className="text-[14px] font-[400] text-[#7C7C7C]">
              Set how you’ll like to receive notification from the users of the
              plateform withing the organization
            </span>
            {/* Toggle Switch Component */}
            <div className="mt-8">
              {Object.entries(settings).map(([key, value]) => (
                <div key={key} className="mb-4 flex items-center">
                  <div
                    className={`relative inline-block w-[42px] h-[25px]  transition duration-200 ease-in ${
                      value ? "bg-[#24A759]" : "bg-gray-300"
                    } rounded-full cursor-pointer`}
                    onClick={() => handleToggle(key)}
                  >
                    <span
                      className={`absolute left-[3px] top-[3px] w-[19px] h-[19px] bg-white rounded-full shadow transform transition-transform duration-200 ease-in-out ${
                        value ? "translate-x-[18px]" : ""
                      }`}
                    ></span>
                  </div>
                  <span className="ml-2 font-[500] text-[16px] text-[#282828] capitalize">
                    {key.replace(/([A-Z])/g, " $1")}
                  </span>
                </div>
              ))}
            </div>
            <hr className="my-8 border-[#B0B0B0] " />
            <h3 className="text-[18px] font-[500]">External</h3>
            <span className="text-[14px] font-[400] text-[#7C7C7C]">
              Set how you’ll like to receive notification from MedInclude
            </span>
            {/* Toggle Switch Component */}
            <div className="mt-8">
              {Object.entries(settingsExternal).map(([key, value]) => (
                <div key={key} className="mb-4 flex items-center">
                  <div
                    className={`relative inline-block w-[42px] h-[25px]  transition duration-200 ease-in ${
                      value ? "bg-[#24A759]" : "bg-gray-300"
                    } rounded-full cursor-pointer`}
                    onClick={() => handleToggleExternal(key)}
                  >
                    <span
                      className={`absolute left-[3px] top-[3px] w-[19px] h-[19px] bg-white rounded-full shadow transform transition-transform duration-200 ease-in-out ${
                        value ? "translate-x-[18px]" : ""
                      }`}
                    ></span>
                  </div>
                  <span className="ml-2 font-[500] text-[16px] text-[#282828] capitalize">
                    {key.replace(/([A-Z])/g, " $1")}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </DoctorDashboardLayout>
  );
};

export default SettingsV2;
