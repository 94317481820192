import React from "react";
import { BiEditAlt } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";

const EditPopupContent = ({ setIsPopupOpen, setIsDeletePopupOpen }) => {
  return (
    <>
      <div className="w-[500px]">
        <p className="text-lg font-[500] text-white mb-2">
          October 17th 2024, 8:30 am
        </p>
        <div className="bg-white p-[35px] rounded-[20px] border border-[#D7D7D7]">
          <p className="text-sm font-[500]">
            Thi  is a note I have scribbled during my introspective
            moment—capturing fleeting thoughts and underlying emotions. It is a
            collection of ideas, random yet profound, woven together by the
            threads of curiosity and reflection.Each word encapsulates a
            snapshot of It is a collection of ideas, random yet profound, woven
            together by the threads of curiosity and reflection.Each word
            encapsulates a snapshot of .
          </p>
        </div>

        <div className="w-full flex justify-end gap-2 mt-2">
          <button className="border-[.7px] border-[#EFEBEB] rounded-full w-[215px] flex items-center justify-center h-[50px] bg-secondary text-white text-[16px] font-semibold gap-2">
            <BiEditAlt size={23} />
            <p>Edit Note</p>
          </button>

          <button
            className="w-[50px] h-[50px] rounded-full flex items-center justify-center bg-white border-[#FF3B30] text-[#FF3B30]"
            onClick={() => {
              setIsPopupOpen(false);
              setIsDeletePopupOpen(true);
            }}
          >
            <AiOutlineDelete size={22} />
          </button>
        </div>
      </div>
    </>
  );
};

export default EditPopupContent;
