import React from "react";
import Logo from "../images/FooterLogo.svg";
import Arrrow from "../images/arrowF.svg";
import { PiLinkedinLogoThin } from "react-icons/pi";
import { SlSocialFacebook } from "react-icons/sl";
import { RxInstagramLogo } from "react-icons/rx";
import { FaXTwitter } from "react-icons/fa6";

const HomeFooter = () => {
  return (
    <>
      <div className="px-[100px] max-[1200px]:px-[70px] max-[750px]:px-[40px] py-[30px] w-full font-poppins bg-[#2A65550D] !overflow-x-hidden ">
        <div className="w-full flex justify-between mb-9 max-[1024px]:flex-wrap max-[750px]:justify-center">
          <div className="w-[33%] max-[1024px]:w-full">
            <img src={Logo} alt="" />

            <p className="text-[24px] leading-[43px] font-[600] max-[750px]:text-[21px] max-[750px]:leading-[35px] text-black pr-6">
              Making healthcare Available, Accessible and Affordable
            </p>
            <p className="font-[500] text-[20px] max-[750px]:text-lg max-[750px]:leading-[25px] leading-[30px] text-primary mt-7">
              Get the latest updates in real-time
            </p>

            <div
              className="flex items-center mt-3"
              style={{ boxshadow: "0px 0px 12.5px 0px #00000026" }}
            >
              <input
                className="outline-none border border-[#B0B0B0] w-full rounded-[15px] h-[60px] max-[750px]:h-[50px] px-3 rounded-r-none"
                type="text"
                placeholder="Email Address"
              />
              <button className="bg-primary h-[60px] max-[750px]:h-[50px] w-[60px] rounded-[15px] rounded-l-none">
                <img src={Arrrow} className="max-[750px]:w-[90%]" alt="" />
              </button>
            </div>
          </div>

          <div className="w-[16%] max-[1024px]:w-[20%] max-[750px]:w-1/2 text-black mt-[50px] flex flex-col items-end max-[1024px]:items-start max-[750px]:items-center ">
            <div>
              <h3 className="font-[600] text-[16px] leadng-[35px] mb-3 ">
                Product
              </h3>
              {/* <p className=" text-[14px] leadng-[35px] py-2">Product</p> */}
              <p className=" text-[14px] leadng-[35px] py-2">Pricing</p>

              <p className=" text-[14px] leadng-[35px] py-2">Request a Demo</p>
            </div>
          </div>

          <div className="w-[16%] max-[1024px]:w-[30%] max-[750px]:w-1/2 text-black mt-[50px] flex flex-col items-end max-[1024px]:items-center max-[750px]:items-center">
            <div>
              <h3 className="font-[600] text-[16px] leadng-[35px] mb-3 ">
                Resources
              </h3>
              <p className=" text-[14px] leadng-[35px] py-2">Blogs</p>
              <p className=" text-[14px] leadng-[35px] py-2">FAQs</p>
              <p className=" text-[14px] leadng-[35px] py-2">Featured on</p>
            </div>
          </div>

          <div className="w-[16%] max-[1024px]:w-[30%] max-[750px]:w-1/2 text-black mt-[50px] flex flex-col items-end max-[1024px]:items-center max-[750px]:items-center ">
            <div>
              <h3 className="font-[600] text-[16px] leadng-[35px] mb-3 ">
                Policy
              </h3>
              <p className=" text-[14px] leadng-[35px] py-2">Privacy Policy</p>
              <p className=" text-[14px] leadng-[35px] py-2">
                Terms & Conditions
              </p>
            </div>
          </div>

          <div className="w-[16%] max-[1024px]:w-[20%] max-[750px]:w-1/2 text-black mt-[50px] flex flex-col items-end max-[1024px]:items-end max-[750px]:items-center">
            <div>
              <h3 className="font-[600] text-[16px] leadng-[35px] mb-3 ">
                About
              </h3>
              <p className=" text-[14px] leadng-[35px] py-2">About Us</p>
              <p className=" text-[14px] leadng-[35px] py-2">Contact Us</p>
            </div>
          </div>
        </div>

        <div className="border-t border-[#EFEBEB] pt-[30px]">
          <div className="w-full flex justify-between items-center max-[750px]:flex-col max-[750px]:gap-3">
            <div>
              <p className="text-sm leading-[35px] text-black">
                © 2024 Medinclude, All Right Reserved.
              </p>
            </div>

            <div className="flex items-center gap-5 text-black">
              <PiLinkedinLogoThin size={24} />
              <SlSocialFacebook size={22} />
              <RxInstagramLogo size={22} />
              <FaXTwitter size={22} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeFooter;
