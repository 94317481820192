import React, { useContext, useState } from "react";
import HomeHeader from "../Component/HomeHeader";
import { TiMinusOutline } from "react-icons/ti";
import MinusImg from "../images/minus.svg";
import PlusImg from "../images/plus.svg";
import HomeFooter from "../Component/HomeFooter";
import TestimonialSmall from "../Component/reusableCompo/TestimonialSmall";
import CheckoutFaq from "../Component/reusableCompo/CheckoutFaq";
import GlobalStorage from "../Storage/ContextProvider";

const faqItem = [
  {
    title: "Simplify up to 50 notes",
    desc: "Simplifying up to 50 notes means turning complex information into clear, concise summaries. This process highlights key points and reduces jargon, making the content more accessible and easier to understand. It’s a valuable tool for saving time and improving comprehension, whether for medical notes, academic research, or professional reports.",
  },
  {
    title: "Translate up to 50 notes",
    desc: "Simplifying up to 50 notes means turning complex information into clear, concise summaries. This process highlights key points and reduces jargon, making the content more accessible and easier to understand. It’s a valuable tool for saving time and improving comprehension, whether for medical notes, academic research, or professional reports.",
  },
  {
    title: "Email Support",
    desc: "For this plan, we offer email support to address your questions and issues promptly.",
  },
  {
    title: "Video tutorials",
    desc: "For this plan, we offer email support to address your questions and issues promptly.",
  },
];

const Pricing = () => {
  const [activeFaq, setActiveFaq] = useState(0);
  const [planSelected, setPlanSelected] = useState("monthly");
  const [selectedOption, setSelectedOption] = useState("free");
  const [isProviderActive, setIsProviderActive] = useState(true);

  const options = [
    {
      id: "free",
      title: "Free Trial",
      description:
        "Experience all our features with a complimentary 14-day trial, absolutely free!",
      price: "$0.00",
      borderColor: "border-[#515A9C]",
      textColor: "text-[#515A9C]",
    },
    {
      id: "pro",
      title: "Provider Pro",
      description:
        "Ideal for healthcare providers to use directly at the point of patient care.",
      price: "$540.00",
      borderColor: "border-[#515A9C]",
      textColor: "text-[#515A9C]",
    },
    {
      id: "custom",
      title: "Custom Plan",
      description:
        "For health organizations with multiple departments/providers.",
      price: "$Custom",
      borderColor: "border-[#515A9C]",
      textColor: "text-[#515A9C]",
    },
  ];
  const {activeMobileNav} = useContext(GlobalStorage)

  return (
    <>
      <HomeHeader activeLink="pricing" />
      <div className={`${activeMobileNav ? "max-h-[calc(100vh-90px)] overflow-hidden": ""}`}>
      <div className="pt-[40px] px-[100px] max-[1300px]:px-[70px] max-[750px]:px-[40px] pb-[80px]  font-poppins">
        <div className="flex gap-[100px] max-[1024px]:flex-col">
          <div className="w-[50%] max-[1024px]:w-full">
            <h2 className="text-primary text-[22px] max-[750px]:text-lg leading-[33px]">
              JOIN MEDINCLUDE TODAY
            </h2>
            <h2 className="text-[50px] leading-[75px] max-[750px]:text-[40px] max-[750px]:leading-[35px] text-[#000] font-semibold max-[750px]:mb-[25px]">
              Select a Plan
            </h2>

            <div className="flex items-center justify-between border-[#D7D7D7] border py-[10px] w-[350px] max-[750px]:w-full px-3">
              <h3
                className={`text-[22px] max-[750px]:text-lg leading-[33px] cursor-pointer ${
                  isProviderActive
                    ? "underline font-bold text-secondary"
                    : "font-normal"
                }`}
                onClick={() => setIsProviderActive(true)}
              >
                For Providers
              </h3>

              <h3
                className={`text-[22px] max-[750px]:text-lg leading-[33px] cursor-pointer ${
                  !isProviderActive
                    ? "underline font-bold text-secondary"
                    : "font-normal"
                }`}
                onClick={() => setIsProviderActive(false)}
              >
                For Hospitals
              </h3>
            </div>

            <div className="my-9">
              {faqItem?.map((item, index) => (
                <>
                  <div className="my-11">
                    <div className="flex justify-between items-center">
                      <p className="text-[20px] max-[750px]:text-[17px] leading-[30px] font-[500] text-black">
                        {item?.title}
                      </p>
                      {activeFaq === index ? (
                        <img
                          src={MinusImg}
                          className="cursor-pointer"
                          alt=""
                          onClick={() => setActiveFaq(index)}
                        />
                      ) : (
                        <img
                          src={PlusImg}
                          className="cursor-pointer"
                          alt=""
                          onClick={() => setActiveFaq(index)}
                        />
                      )}
                    </div>
                    {activeFaq === index && (
                      <>
                        <div>
                          <p className="text-sm leading-[21px] text-grey mt-6 pr-7">
                            {item?.desc}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>

          <div className="w-[50%] max-[1024px]:w-full">
            <div className="flex items-center  h-[50px] w-full border-[2px] border-[#EFEBEB] rounded-[10px] ">
              <div
                className={` ${
                  planSelected === "monthly"
                    ? "bg-black text-white "
                    : "text-grey"
                } w-[50%]  rounded-[10px]  h-full flex items-center justify-center font-semibold max-[750px]:text-[16px] max-[430px]:text-sm text-[18px] leading-[27px] cursor-pointer `}
                onClick={() => setPlanSelected("monthly")}
              >
                Monthly
              </div>
              <div
                className={` ${
                  planSelected === "annually"
                    ? "bg-black text-white "
                    : "text-grey"
                } w-[50%] rounded-[10px]  h-full flex items-center justify-center font-semibold text-[18px] max-[750px]:text-[16px] max-[430px]:text-[13px]  max-[750px]:leading-[22px] leading-[27px] cursor-pointer `}
                onClick={() => setPlanSelected("annually")}
              >
                Annually{" "}
                <span className="ml-2 max-[430px]:ml-1 text-[12px] max-[750px]:text-[10px] leading-[18px] font-normal max[750px]:hidden ">
                  (save up to 10%)
                </span>
              </div>
            </div>

            <div className="mt-11">
              {options.map((option) => (
                <label
                  key={option.id}
                  className={`block p-4 border-2 rounded-lg my-6 cursor-pointer ${
                    selectedOption === option.id
                      ? `${option.borderColor} bg-[#515A9C1A]
]`
                      : "border-gray-300"
                  } transition-all duration-200`}
                  onClick={() => setSelectedOption(option.id)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div
                        className={`w-5 h-5 rounded-full flex justify-center items-center border-2 bg-white  ${
                          selectedOption === option.id
                            ? `${option.borderColor} `
                            : "border-[#47BD68]"
                        }`}
                      >
                        {selectedOption === option.id && (
                          <div
                            className={`w-3 h-3 rounded-full ${
                              selectedOption === option.id
                                ? `${option.textColor} bg-[#515A9C] `
                                : "bg-transparent"
                            }`}
                          ></div>
                        )}
                      </div>
                      <h3 className="ml-4 mt-3 text-[24px] max-[750px]:text-[20px]  max-[750px]:leading-[24px] leading-[36px] text-black font-semibold">
                        {option.title}
                      </h3>
                    </div>
                    <p className=" mt-3 text-[24px] leading-[36px] max-[750px]:text-[20px]  max-[750px]:leading-[28px] text-black font-bold">
                      {option.price}
                    </p>
                  </div>
                  <p className="mt-2 ml-8 text-grey text-sm w-[75%] leading-[21px] ">
                    {option.description}
                  </p>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
      <CheckoutFaq />

      <div className="mb-[80px]">
        <TestimonialSmall />
      </div>

      <HomeFooter />
      </div>
    </>
  );
};

export default Pricing;
