import React from "react";

const BreadCrumb = ({ parent, page }) => {
  return (
    <div className="flex flex-row items-center mb-6 ml-5">
      <h2 className="text-2xl font-semibold text-gray-800">{parent}</h2>
      <img
        className="h-[24px] w-[24px] mx-2"
        src={require("../../../images/arrow-breadcrumb.png")}
      />
      <span className="text-[18px] font-[400]"> {page}</span>
    </div>
  );
};

export default BreadCrumb;
