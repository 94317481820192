import React from "react";

import GoogleDocsViewer from "react-google-docs-viewer";

const DocumentView = () => {
  return (
    <GoogleDocsViewer
      width="600px"
      height="780px"
      fileUrl="https://med-report-ca-central-1.s3.ca-central-1.amazonaws.com/medical-reports/1736406403011_1736407121728-merged.docx"
    />
  );
};
 
export default DocumentView;
