import React, { useState } from "react";
import DoctorDashboardLayout from "../../../layout/doctor";
import AccordionItem from "../../../component/accordion";
import BreadCrumb from "../../../component/breadcrumb";

const PrivacyV2 = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <DoctorDashboardLayout>
      <BreadCrumb parent={"Help and Support"} page={"Privacy"} />
      <div className="w-full ml-0 p-6">
        <AccordionItem
          title="Privacy Policy"
          content="Our privacy policy outlines how we collect, use, and protect your data. We ensure that your information is secure and used only in accordance with our policy."
          isOpen={openIndex === 0}
          index={0}
          className={" rounded-t-[20px] "}
          onToggle={() => toggleAccordion(0)}
        />

        <AccordionItem
          title="Terms of Use"
          content="By using this platform, you agree to adhere to our terms of use. Ensure that your activities comply with our guidelines and maintain a positive environment."
          className={
            openIndex === 1 ? " rounded-b-[0px] " : " rounded-b-[20px] "
          }
          isOpen={openIndex === 1}
          index={1}
          onToggle={() => toggleAccordion(1)}
        />
      </div>
    </DoctorDashboardLayout>
  );
};

export default PrivacyV2;
