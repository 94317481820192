import React from "react";
import { CiSearch } from "react-icons/ci";

const Header = ({ setIsSidebarOpen, isSidebarOpen }) => {
  return (
    <header className="z-10 flex items-center justify-between px-4 py-2 bg-white border-b border-gray-300">
      <div className="flex flex-row w-[492px] items-center p-5">
        <button onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          <img
            className="w-[24px] h-[24px]"
            src={require("../../../../images/menu.png")}
          />
        </button>

        <div className="flex items-center ml-[25px] w-full h-[45px] border-[2px]  bg-[#fff] dark:bg-[var(--dark-primary)] rounded-[100px] relative text-[#000]">
          <CiSearch
            color="black"
            className="w-[24px] h-[24px]  absolute left-[10px]"
          />
          <input
            className=" w-full outline-none ml-[45px] mr-[10px] placeholder-[#7C7C7C]"
            type="text"
            placeholder="Search Patient"
          />
        </div>
      </div>

      <div className="flex  items-center space-x-4">
        <button>
          <img
            className="w-[24px] h-[24px]"
            src={require("../../../../images/notification.png")}
          />
        </button>

        <img
          className="w-[60px] h-[60px]"
          src={require("../../../../images/avatar.png")}
        />
        <span>Titobi</span>
      </div>
    </header>
  );
};

export default Header;
