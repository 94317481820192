import {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
//   import axios from 'axios';
//   import { getCookie, isAuth } from "../auth/helper";
//   import BASE_URL from "../baseUrl";
const GlobalStorage = createContext();
export default GlobalStorage;

export const DataStorage = ({ children }) => {
  const [helloCheck, setHelloCheck] = useState("");
  const [settingActive, setSettingActive] = useState(false);
  const [supportActive, setSupportActive] = useState(false);
  const [darkMode, setDarkMode] = useState(localStorage.getItem("darkMode"));
  const [checked, setChecked] = useState(localStorage.getItem("darkMode"));
  const [addPatient, setAddPatient] = useState(false);
  const [onBoard, setOnBoard] = useState(0);
  const [excelFileData, setExcelData] = useState();
  const [createDoctor, setCreateDoctor] = useState(false);
  const [createDepartment, setCreateDepartment] = useState(false);
  const [createHospital, setCreateHospital] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeMobileNav, setactiveMobileNav] = useState(false);

  const [patientOpen, setPatientOpen] = useState(false);
  const [showSearachBar, setShowSearchBar] = useState(false);
  const [patients, setPatients] = useState([]);
  const [patientDetail, setPatientDetail] = useState();
  const [rawData, setRawData] = useState("");
  const [processedData, setProcessedData] = useState("");
  const [newPatient1, setNewPatient1] = useState(false);
  const [checkedDepartments, setCheckedDepartmeant] = useState([]);
  const [selectPatient, setSelectPatient] = useState(false);
  const [planType, setPlanType] = useState("");
  const [planDuration, setPlanDuration] = useState();
  const [hospitalProfile, setHospitalProfile] = useState();
  const [doctorProfile, setDoctorProfile] = useState();
  const [patientProfile, setPatientProfile] = useState();
  const [doctorActiveBar, setDoctorActiveBar] = useState(false);
  const [hospitalDepartment, setHospitalDepartment] = useState();
  const [hospitalDoctors, setHospitalDoctors] = useState();
  const [refreshDepartment, setRefreshDepartment] = useState(false);
  const [isRequestDemo, setRequestDemo] = useState(false);
  const [toggleProduct, setToggleProduct] = useState(false);
  const [refreshPatient, setRefreshPatient] = useState(false);
  const [doctorPatient, setDoctorPatient] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [is2FaConfirm, set2FaConfirm] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <GlobalStorage.Provider
      value={{
        isCollapsed,
        setIsCollapsed,
        isDropdownOpen,
        setIsDropdownOpen,
        activeMobileNav,
        setactiveMobileNav,
        helloCheck,
        setHelloCheck,
        settingActive,
        setSettingActive,
        supportActive,
        setSupportActive,
        darkMode,
        setDarkMode,
        checked,
        setChecked,
        addPatient,
        setAddPatient,
        onBoard,
        setOnBoard,
        excelFileData,
        setExcelData,
        createDoctor,
        setCreateDoctor,
        createDepartment,
        setCreateDepartment,
        createHospital,
        setCreateHospital,
        isOpen,
        setIsOpen,
        patientOpen,
        setPatientOpen,
        showSearachBar,
        setShowSearchBar,
        processedData,
        setProcessedData,
        rawData,
        setRawData,
        patients,
        setPatients,
        patientDetail,
        setPatientDetail,
        newPatient1,
        setNewPatient1,
        checkedDepartments,
        setCheckedDepartmeant,
        selectPatient,
        setSelectPatient,
        planType,
        setPlanType,
        planDuration,
        setPlanDuration,
        hospitalProfile,
        setHospitalProfile,
        doctorActiveBar,
        setDoctorActiveBar,
        hospitalDepartment,
        setHospitalDepartment,
        refreshDepartment,
        setRefreshDepartment,
        isRequestDemo,
        setRequestDemo,
        hospitalDoctors,
        setHospitalDoctors,
        toggleProduct,
        setToggleProduct,
        patientProfile,
        setPatientProfile,
        refreshPatient,
        setRefreshPatient,
        doctorPatient,
        setDoctorPatient,
        doctorProfile,
        setDoctorProfile,
        activeTab,
        setActiveTab,
        is2FaConfirm,
        set2FaConfirm,
      }}
    >
      {children}
    </GlobalStorage.Provider>
  );
};
