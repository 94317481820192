import React, { useContext, useState } from "react";
import Logo from "../images/Logo.svg";
import { Link } from "react-router-dom";
import Login from "../images/door-01.svg";
import { FaRegUserCircle } from "react-icons/fa";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa6";
import GlobalStorage from "../Storage/ContextProvider";

const HomeHeader = ({ activeLink }) => {
  const [activeResource, setActiveResource] = useState(false);
  const [activeResourceMobile, setActiveResourceMobile] = useState(false);

  const { activeMobileNav, setactiveMobileNav } = useContext(GlobalStorage);
  const boxShadowStyle = {
    boxShadow: activeResource ? "0px 0px 25px 0px #00000026" : "none",
  };
  return (
    <>
      <div
        className={`${
          activeMobileNav ? "" : "block"
        } px-[100px] max-[1200px]:px-[70px] max-[750px]:px-[40px] py-4 font-poppins text-black sticky top-0 bg-white z-50 `}
      >
        <div className="h-[60px] flex items-center max-[1024px]:hidden">
          <div className="w-[10%] max-[1350px]:w-[12%]">
            <img src={Logo} className="" alt="" />
          </div>
          <div className="w-[70%] max-[1350px]:w-[63%]  flex justify-center items-center max-[1350px]:gap-[45px] gap-[70px]">
            <Link
              to="/"
              className={`text-sm leading-[21px] ${
                activeLink === "home"
                  ? "font-bold text-secondary underline"
                  : ""
              }  `}
            >
              Home
            </Link>
            <Link
              to="/about"
              className={`text-sm leading-[21px] ${
                activeLink === "about"
                  ? "font-bold text-secondary underline"
                  : ""
              }  `}
            >
              About
            </Link>
            <Link
              to="/pricing"
              className={`text-sm leading-[21px] ${
                activeLink === "pricing"
                  ? "font-bold text-secondary underline"
                  : ""
              }  `}
            >
              Pricing
            </Link>

            <button
              className={` relative text-black p-[10px]  ${
                activeResource ? " " : ""
              } `}
              // style={boxShadowStyle}
              onClick={() => setActiveResource(!activeResource)}
            >
              <div
                className={` text-sm leading-[21px] !z-[100] text-black ${
                  activeLink === "resources"
                    ? "font-bold text-secondary underline"
                    : ""
                } `}
              >
                Resources
              </div>

              {activeResource && (
                <>
                  <div
                    className="absolute top-[2px] p-[10px] w-[106px] -left-2 bg-white pt-1 border border-[#D7D7D7] z-30 "
                    style={boxShadowStyle}
                  >
                    <p
                      className={` text-sm leading-[21px] mb-4 !z-[100] text-black ${
                        activeLink === "resources"
                          ? "font-bold text-secondary underline"
                          : ""
                      } `}
                    >
                      {" "}
                      Resources
                    </p>

                    <Link
                      to="/resources/blogs"
                      className="text-[14px] leading-[24px] "
                    >
                      {" "}
                      Blog
                    </Link>
                    <br />

                    <div className="pt-2">
                      <Link
                        to="/resources/faqs"
                        className="text-[14px] leading-[24px]"
                      >
                        {" "}
                        FAQs
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </button>

            <Link
              to="/contact"
              className={`text-sm leading-[21px] ${
                activeLink === "contact"
                  ? "font-bold text-secondary underline"
                  : ""
              }  `}
            >
              Contact Us
            </Link>
          </div>

          <div className="w-[20%] max-[1350px]:w-[25%] flex justify-end gap-5">
            <div className="flex items-center gap-2">
              <img src={Login} alt="" />
              <div
                className="text-sm leading-[21px]"
                // onClick={() => setactiveMobileNav(false)}
              >
                Login
              </div>
            </div>

            <button className="flex items-center text-sm leading-[21px] bg-primary text-white py-[6px] px-[12px] rounded-full gap-2 max-[1350px]:gap-1 w-[170px] ">
              <FaRegUserCircle size={18} />
              <p className="max-[1350px]:text-sm">Create Account</p>
            </button>
          </div>
        </div>
        <div className="hidden max-[1024px]:flex h-[60px]  justify-between items-center">
          <div>
            <img src={Logo} className="" alt="" />
          </div>
          <div>
            <HiOutlineMenuAlt1
              className="text-primary text-[30px] cursor-pointer"
              onClick={() => setactiveMobileNav(true)}
            />
          </div>
        </div>
      </div>

      {activeMobileNav && (
        <>
          <div className="min-[1025px]:hidden absolute top-[0px] h-[98vh] overflow-y-hidden bg-white w-[400px] z-[101] px-[40px] mt-5">
            <div className="flex justify-between items-center">
              <img src={Logo} className="w-[120px] object-contain" alt="" />
              <IoMdClose
                className="text-[24px] cursor-pointer"
                onClick={() => setactiveMobileNav(false)}
              />
            </div>

            <div className="flex flex-col gap-6 text-[16px] leadng-[21px] mt-12">
              <Link
                to="/"
                className={`${
                  activeLink === "home"
                    ? "font-semibold text-secondary underline tracking-wide"
                    : ""
                }`}
                onClick={() => setactiveMobileNav(false)}
              >
                Home
              </Link>

              <Link
                to="/about"
                className={`${
                  activeLink === "about"
                    ? "font-semibold text-secondary underline tracking-wide"
                    : ""
                }`}
                onClick={() => setactiveMobileNav(false)}
              >
                About
              </Link>

              <Link
                to="/pricing"
                className={`${
                  activeLink === "pricing"
                    ? "font-semibold text-secondary underline tracking-wide"
                    : ""
                }`}
                onClick={() => setactiveMobileNav(false)}
              >
                Pricing
              </Link>
              <button
                className={` flex items-center gap-1  ${
                  activeResourceMobile ? " " : ""
                } `}
                // style={boxShadowStyle}
                onClick={() => setActiveResourceMobile(!activeResourceMobile)}
              >
                <div
                  className={` text-start  ${
                    activeLink === "resources"
                      ? "font-bold text-secondary underline"
                      : ""
                  } `}
                >
                  Resources
                </div>
                {/* <FaAngleDown className="mt-1" /> */}
              </button>

              {activeResourceMobile && (
                <>
                  <div className="pl-2 flex flex-col gap-4">
                    <Link
                      to="/resources/blogs"
                      className={`${
                        activeLink === "contact"
                          ? "font-semibold text-secondary underline tracking-wide"
                          : ""
                      }`}
                      onClick={() => setactiveMobileNav(false)}
                    >
                      Blog
                    </Link>
                    <Link
                      to="/resources/faqs"
                      className={`${
                        activeLink === "respurce"
                          ? "font-semibold text-secondary underline tracking-wide"
                          : ""
                      }`}
                      onClick={() => setactiveMobileNav(false)}
                    >
                      FAQs
                    </Link>
                  </div>
                </>
              )}

              {/* <p>Resources</p> */}

              <Link
                to="/contact"
                className={`${
                  activeLink === "contact"
                    ? "font-semibold text-secondary underline tracking-wide"
                    : ""
                }`}
                onClick={() => setactiveMobileNav(false)}
              >
                Contact Us
              </Link>
            </div>

            <div className=" mt-9">
              <div className="flex items-center gap-2 mb-5">
                <img src={Login} alt="" />
                <div className="text-sm leading-[21px]  ">Login</div>
              </div>

              <button className="flex items-center text-sm leading-[21px] bg-primary text-white py-[6px] px-[12px] rounded-full gap-2 max-[1350px]:gap-1 w-[170px] ">
                <FaRegUserCircle size={18} />
                <p className="max-[1350px]:text-sm">Create Account</p>
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HomeHeader;
