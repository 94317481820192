import React, { useContext, useState } from "react";

import { IoSunnyOutline } from "react-icons/io5";
import { IoMdMoon } from "react-icons/io";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import GlobalStorage from "../../../Storage/ContextProvider";
import Hsidebar from "../../../Component/Hospital/Layout/Hsidebar";

const Appearances = () => {
  const { darkMode, setDarkMode, checked, setChecked } =
    useContext(GlobalStorage);

  const marks = [
    {
      value: 0,
    },
    {
      value: 33,
    },
    {
      value: 67,
    },
    {
      value: 100,
    },
  ];

  function valuetext(value) {
    return `${value}`;
  }

  //   function valueLabelFormat(value) {
  //     return marks.findIndex((mark) => mark.value === value) + 1;
  //   }

  const handleToggle = () => {
    setChecked(!checked);
    setDarkMode(!darkMode);
  };

  return (
    <Hsidebar activeLink="Appearance">
      <div className="p-[20px] text-[#052721] dark:text-[#fff]">
        <div className=" border-b border-[var(--doctor-color)] dark:text-[#fff] pb-[15px]">
          <div>
            <h2 className="font-[500] text-[25px]">Appearance</h2>
            <p className="font-[300] text-[16px] text-[var(--doctor-color)] dark:text-[#fff]">
              Customization your appearance{" "}
            </p>
          </div>
        </div>
        <div>
          <h3 className="text-[20px]  font-[500] py-[20px]">
            Theme and Layout
          </h3>

          <div className="flex  gap-[50px] items-center">
            <p className="text-[20px]">Mode</p>
            <div
              className=" h-[42px] ml-[110px] rounded-lg flex"
              style={{
                backgroundImage:
                  "linear-gradient(to right, rgba(1, 104, 85, 1) , rgba(103,165,153,.7))",
              }}
            >
              <div className="px-[20px] flex items-center  text-[#fff] h-full gap-[15px]">
                <IoSunnyOutline size={22} />
                <p className="text-[16px]">Light Mode</p>
              </div>
              <div className="toggle-slider">
                <input
                  type="checkbox"
                  id="toggle"
                  checked={darkMode}
                  onChange={handleToggle}
                  className="inputCheck"
                />
                <label htmlFor="toggle" className="slider" />
              </div>
              <div className="px-[20px] flex items-center  text-[var(--doctor-color)] h-full gap-[15px] ml-[50px]">
                <IoMdMoon size={22} />
                <p className="text-[16px]">Dark Mode </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[30px]">
          <h3 className="text-[20px]  font-[500] py-[20px]">
            Font And Display
          </h3>
          <div className="flex gap-[55px] mb-[50px] items-center">
            <p className="text-[20px] ">Heading Font Size</p>
            <div className="w-[50%] mt-[30px] relative">
              <Box className="relative">
                <Slider
                  aria-label="Restricted values"
                  defaultValue={0}
                  sx={{
                    color: darkMode ? "#fff" : "#016855",
                  }}
                  // valueLabelFormat={valueLabelFormat}
                  getAriaValueText={valuetext}
                  step={null}
                  valueLabelDisplay="off"
                  marks={marks}
                />
                <p className="absolute -top-[30px] -left-[10px] text-[13px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                  Aa
                </p>
                <p className="absolute -top-[30px] left-[31%] text-[15px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                  Aa
                </p>
                <p className="absolute -top-[30px] left-[65%] text-[17px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                  Aa
                </p>
                <p className="absolute -top-[30px] left-[98%] text-[20px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                  Aa
                </p>
              </Box>
            </div>
          </div>

          <div className="flex gap-[92px] items-center mt-[50px]">
            <p className="text-[20px] ">Text Font Size</p>
            <div className="w-[50%] mt-[30px] relative">
              <Box className="relative">
                <Slider
                  aria-label="Restricted values"
                  defaultValue={0}
                  sx={{
                    color: darkMode ? "#fff" : "#016855",
                  }}
                  // valueLabelFormat={valueLabelFormat}
                  getAriaValueText={valuetext}
                  step={null}
                  valueLabelDisplay="off"
                  marks={marks}
                />
                <p className="absolute -top-[30px] -left-[10px] text-[13px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                  Aa
                </p>
                <p className="absolute -top-[30px] left-[31%] text-[15px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                  Aa
                </p>
                <p className="absolute -top-[30px] left-[65%] text-[17px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                  Aa
                </p>
                <p className="absolute -top-[30px] left-[98%] text-[20px] text-[rgba(0,0,0,.4)] dark:text-[rgba(255,255,255,.4)]">
                  Aa
                </p>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Hsidebar>
  );
};

export default Appearances;
