import React, { useContext, useState } from "react";
import { CiSearch } from "react-icons/ci";
import Header from "../header";
import { useLocation, useNavigate, Link } from "react-router-dom";
import GlobalStorage from "../../../../Storage/ContextProvider";

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen, menu }) => {
  const { isCollapsed, setIsCollapsed, isDropdownOpen, setIsDropdownOpen } =
    useContext(GlobalStorage);

  const helpLinks = [
    "/v2/doctor/resources",
    "/v2/doctor/privacy",
    "/v2/doctor/contact",
  ];

  const route = useLocation();

  return (
    <>
      <div
        className={`fixed static left-0 top-0 inset-y-0 left-0 z-30 bg-white border-[1px] text-black transform transition-transform duration-300 ease-in-out lg:translate-x-0 lg:static ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } ${isCollapsed ? "w-16" : "w-66"} transition-all duration-300`}
      >
        <div className="p-4 text-lg font-bold ">
          {isCollapsed ? (
            <img
              className="w-[54px] h-[60px]"
              src={require("../../../../images/logo-single.png")}
            />
          ) : (
            <img
              className="w-[166px] h-[60px]"
              src={require("../../../../images/header-logo.png")}
            />
          )}
        </div>
        <nav className="mt-[34px] space-y-8">
          <div className="flex  h-[80vh] flex-col justify-between">
            <div>
              {menu?.map((item) => (
                <Link
                  to={{
                    pathname: item?.route,
                  }}
                  className={`block my-5 px-4 py-2 rounded-[8px] hover:bg-[#2A6555] hover:text-white ${
                    route?.pathname?.endsWith(item?.route)
                      ? "bg-[#2A6555] text-white"
                      : ""
                  } ${isCollapsed ? "mx-0 text-center" : "mx-5"}`}
                >
                  <div className="flex flex-row items-center">
                    <img
                      className="w-[24px] h-[24px] mask-[red]"
                      src={item?.logo}
                    />
                    {!isCollapsed && (
                      <span className="ml-2 text-[14px] font-[500]">
                        {item?.title}
                      </span>
                    )}
                  </div>
                </Link>
              ))}
              <div>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className={`block my-5 px-4 py-2 rounded-[8px] hover:bg-[#2A6555] hover:text-white ${
                    helpLinks?.includes(route?.pathname)
                      ? "bg-[#2A6555] text-white"
                      : ""
                  } ${isCollapsed ? "mx-0 text-center" : "mx-5"}`}
                >
                  <div className="flex flex-row items-center">
                    <img
                      className="w-[24px] h-[24px]  "
                      src={require("../../../../images/customer-support.png")}
                    />
                    {!isCollapsed && (
                      <span className="ml-2 text-[14px] font-[500]">
                        Help and Support
                      </span>
                    )}
                    <svg
                      className={`w-4 h-4 ml-3 transform transition-transform ${
                        isDropdownOpen ? "rotate-180" : ""
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </div>
                </button>
                {isDropdownOpen && (
                  <div className={`pl-8 ${isCollapsed ? "hidden" : ""}`}>
                    <Link
                      to={{
                        pathname: "/v2/doctor/resources",
                      }}
                      className={`block my-5 mx-5 px-4 py-2 rounded-[8px] text-sm hover:bg-[#515A9C33] ${
                        route?.pathname?.endsWith("/v2/doctor/resources")
                          ? "bg-[#515A9C33] text-black"
                          : ""
                      }`}
                    >
                      <div className="flex flex-row items-center">
                        <img
                          className="w-[24px] h-[24px] mask-[red]"
                          src={require("../../../../images/resources.png")}
                        />
                        {!isCollapsed && (
                          <span className="ml-2 text-[14px] font-[500]">
                            Resources
                          </span>
                        )}
                      </div>
                    </Link>
                    <Link
                      to={{
                        pathname: "/v2/doctor/privacy",
                      }}
                      className={`block my-5 mx-5 px-4 py-2 rounded-[8px] text-sm hover:bg-[#515A9C33] ${
                        route?.pathname?.endsWith("/v2/doctor/privacy")
                          ? "bg-[#515A9C33] text-black"
                          : ""
                      }`}
                    >
                      <div className="flex flex-row items-center">
                        <img
                          className="w-[24px] h-[24px] mask-[red]"
                          src={require("../../../../images/privacy.png")}
                        />
                        {!isCollapsed && (
                          <span className="ml-2 text-[14px] font-[500]">
                            Privacy
                          </span>
                        )}
                      </div>
                    </Link>
                    <Link
                      to={{
                        pathname: "/v2/doctor/contact",
                      }}
                      className={`block my-5 mx-5 px-4 py-2 rounded-[8px] text-sm hover:bg-[#515A9C33]" ${
                        route?.pathname?.endsWith("/v2/doctor/contact")
                          ? "bg-[#515A9C33] text-black"
                          : ""
                      }`}
                    >
                      <div className="flex flex-row items-center">
                        <img
                          className="w-[24px] h-[24px] mask-[red]"
                          src={require("../../../../images/question.png")}
                        />
                        {!isCollapsed && (
                          <span className="ml-2 text-[14px] font-[500]">
                            Contact us
                          </span>
                        )}
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-end mt-[-20vh]">
              <button
                onClick={() => setIsCollapsed(!isCollapsed)}
                className="flex flex-row items-center "
              >
                {!isCollapsed && (
                  <span className="mr-[10px] text-[#7C7C7C]">Collapse</span>
                )}
                <img
                  className={`w-[30px] h-[30px] transition-all duration-300 ${
                    isCollapsed ? "transform rotate-180" : "transform rotate-0"
                  }`}
                  src={require("../../../../images/collapse.png")}
                />
              </button>
            </div>
            <div>
              <a
                href="#"
                className={`block my-5 px-4 py-2 rounded-[8px] hover:bg-gray-100 hover:text-white ${
                  isCollapsed ? "mx-0 text-center" : "mx-5"
                }`}
              >
                <div className="flex flex-row">
                  <img
                    className={`w-[24px] h-[24px] mask-[red] `}
                    src={require("../../../../images/logout.png")}
                  />
                  {!isCollapsed && (
                    <span className="ml-2 text-[red]">Logout</span>
                  )}
                </div>
              </a>
            </div>
          </div>
        </nav>
      </div>
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-20 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </>
  );
};

export default Sidebar;
