import React, { useState } from "react";
import Sidebar from "../../component/common/sidebar";
import Header from "../../component/common/header";

const DoctorDashboardLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const menu = [
    {
      title: "Dashboard",
      route: "/v2/doctor/dashboard",
      logo: require("../../../images/menu-square.png"),
    },
    {
      title: "Patients",
      route: "/v2/doctor/patients",
      logo: require("../../../images/svg/patient.svg").default,
    },
    {
      title: "Settings",
      route: "/v2/doctor/settings",
      logo: require("../../../images/settings.png"),
    },
  ];
  return (
    <>
      <div className="flex h-screen font-poppins overflow-hidden">
        <Sidebar
          menu={menu}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />

        <div className="flex flex-col flex-1">
          <Header
            setIsSidebarOpen={setIsSidebarOpen}
            isSidebarOpen={isSidebarOpen}
          />
          {/* Content */}
          <main className="flex-1 p-4 pt-[20px] w-full overflow-scroll">
            {children}
          </main>
        </div>
      </div>
    </>
  );
};

export default DoctorDashboardLayout;
