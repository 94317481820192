import React from "react";
import InputImage from "../../../images/landing/input.svg";
import TranslationImage from "../../../images/landing/Translate.svg";
import SharingImage from "../../../images/landing/sharing.svg";

const StepsNew = () => {
  return (
    <>
      <div className="px-[100px] max-[1200px]:px-[70px] max-[750px]:px-[40px] bg-white font-poppins  text-black py-[80px] overflow-x-hidden">
        <h1 className="text-black text-[30px] leading-[45px] max-[750px]:text-[22px] max-[750px]:leading-[35px] text-center font-semibold">
          BETTER PROVIDER-PATIENT COMMUNICATION IN 3 EASY STEPS
        </h1>

        <div className="flex  w-full items-center justify-between mt-14 flex-wrap">
          <div className="w-[30%] max-[1024px]:w-[47%] max-[750px]:w-full flex justify-center flex-col items-center mb-10  ">
            <img src={InputImage} className="w-[210px] h-[210px]" alt="" />
            <button className="bg-[#2A65551A] text-primary font-[500] p-[8px] text-[22px] max-[750px]:text-[16px] max-[750px]:leading-[22px] leading-[33px]">
              STEP1
            </button>

            <h3 className="text-black text-center font-semibold text-lg leading-[53px]  max-[750px]:text-[16px] max-[750px]:leading-[45px]">
              INPUT MEDICAL TEXT
            </h3>

            <p className="text-[16px]  max-[750px]:text-[14px] max-[750px]:leading-[22px] leading-[24px] text-greyb text-center">
              U{" "}
              <span className=" -ml-[5px] font-bold text-primary">
                pload, type, paste, or record speech
              </span>
              , and automatically simplify to grade 5 reading level or below.
            </p>
          </div>
          <div className="w-[30%] max-[1024px]:w-[47%] max-[750px]:w-full flex justify-center flex-col items-center mb-10 ">
            <img
              src={TranslationImage}
              className="w-[210px] h-[210px]"
              alt=""
            />
            <button className="bg-[#2A65551A] text-primary font-[500]  max-[750px]:text-[16px] max-[750px]:leading-[22px] p-[8px] text-[22px] leading-[33px]">
              STEP2
            </button>

            <h3 className="text-black font-semibold text-lg max-[1100px]:text-[18px] text-center max-[750px]:text-[16px] max-[750px]:leading-[45px]">
              MULTI-LANGUAGE TRANSLATION
            </h3>

            <p className="text-[16px] leading-[24px] max-[750px]:text-[14px] max-[750px]:leading-[22px] text-grey text-center">
              Translate
              <span className="font-bold text-primary">already simplified</span>
              text into{" "}
              <span className="font-bold text-primary">
                multiple languages
              </span>{" "}
              to better support a wider, more diverse patient group
            </p>
          </div>
          <div className="w-[30%] max-[1024px]:w-[47%] max-[750px]:w-full flex justify-center flex-col items-center  ">
            <img src={SharingImage} className="w-[210px] h-[210px]" alt="" />
            <button className="bg-[#2A65551A] text-primary font-[500] p-[8px] text-[22px] leading-[33px]  max-[750px]:text-[16px] max-[750px]:leading-[22px]">
              STEP3
            </button>

            <h3 className="text-black font-semibold text-lg leading-[53px] max-[750px]:text-[16px] max-[750px]:leading-[45px]">
              EASY SHARING
            </h3>

            <p className="text-[16px] leading-[24px] text-grey text-center max-[750px]:text-[14px] max-[750px]:leading-[22px]">
              Easily share simplified and/or translated{" "}
              <span className=" font-bold text-primary">
                medical information
              </span>{" "}
              via our patient portal or direct print. , and automatically
              simplify to grade 5 reading level or below.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepsNew;
