import React from "react";
import { RiUser6Line } from "react-icons/ri";
import InputComponent from "./InputComponent";

const RequestDemo = () => {
  return (
    <>
      <div className=" font-poppins">
        {/* <div className="w-full flex justify-center items-center mb-14">
          <button
            className="flex items-center justify-center h-[97px] w-full bg-primary rounded-[20px] gap-3"
            style={{
              boxShadow: " 0px 2px 8.9px 0px #00000040",
            }}
          >
            <div className="p-2 bg-white text-primary rounded-full font-bold">
              <RiUser6Line size={22} />
            </div>
            <h3 className="font-bold text-[35px] leading-[52px] text-white">
              Book Demo
            </h3>
          </button>
        </div> */}

        <div className="">
          <h2 className="font-bold text-[50px] max-[750px]:text-[32px] max-[750px]:leading-[55px] leading-[68px] text-black pb-7 ">
            Book a Demo
          </h2>

          <div className="flex flex-col justify-between h-full">
            <div className="">
              <InputComponent
                type="text"
                label="Full Name"
                placeholderText="Full Name"
              />
              <InputComponent
                type="text"
                label="Hospital Name"
                placeholderText="Hospital Full Name"
              />

              <InputComponent
                type="text"
                label="Email Address"
                placeholderText="johndoe@gmail.com"
              />

              <InputComponent
                type="text"
                label="Phone Number"
                placeholderText="Phone Number"
              />
            </div>
            <button className="bg-primary text-white h-[60px] max-[750px]:h-[50px] w-full text-center font-bold text-xl max-[750px]:text-lg rounded-lg mt-16 ">
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestDemo;
