import React from "react";
import LanguageIcon from "../../../images/landing/features/language-square.svg";
import NoteIcon from "../../../images/landing/features/note.svg";
import DatabaseIcon from "../../../images/landing/features/database-01.svg";
import Slider from "react-slick";
import SliderImg from "../../../images/landing/Subtract.svg";
import { FaArrowRightLong } from "react-icons/fa6";

import FeatureCard from "./FeatureCard";

const FeatureMain = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2, // Show 2 full slides in the center
    slidesToScroll: 1,
    centerMode: true, // Enable center mode
    centerPadding: "15%", // Adjust this to control the visibility of the half slides
    responsive: [
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 1, // Show 1 full slide
          centerPadding: "25%", // More padding for smaller screens
        },
      },
      {
        breakpoint: 600, // Mobile view
        settings: {
          slidesToShow: 1,
          centerPadding: "0%",
        },
      },
    ],
  };

  const items = [
    {
      id: 1,
      title: "Blog Post 101 - The Art of Storytelling",
      date: "12/2/2024",
      time: "4min",
      desc: "This world is built on blood, nobody is a saint here.. Welcome to the badlands..I have heard stories, there are places..",
    },
    {
      id: 2,
      title: "Blog Post 101 - The Art of Storytelling",
      date: "12/2/2024",
      time: "4min",
      desc: "This world is built on blood, nobody is a saint here.. Welcome to the badlands..I have heard stories, there are places..",
    },
    {
      id: 3,
      title: "Blog Post 101 - The Art of Storytelling",
      date: "12/2/2024",
      time: "4min",
      desc: "This world is built on blood, nobody is a saint here.. Welcome to the badlands..I have heard stories, there are places..",
    },
    {
      id: 4,
      title: "Blog Post 101 - The Art of Storytelling",
      date: "12/2/2024",
      time: "4min",
      desc: "This world is built on blood, nobody is a saint here.. Welcome to the badlands..I have heard stories, there are places..",
    },
    {
      id: 5,
      title: "Blog Post 101 - The Art of Storytelling",
      date: "12/2/2024",
      time: "4min",
      desc: "This world is built on blood, nobody is a saint here.. Welcome to the badlands..I have heard stories, there are places..",
    },
  ];

  return (
    <>
      <div className=" py-[60px] font-poppins bg-[#2A65551A] overflow-x-hidden">
        <div className="">
          <h2 className="text-center text-[30px] leading-[45px] text-black font-semibold mb-8">
            FEATURED ON
          </h2>

          {/* <FeatureCard /> */}
          <div className="w-full">
            <Slider {...settings}>
              <FeatureCard />
              <FeatureCard />
              <FeatureCard />
              <FeatureCard />
              <FeatureCard />
            </Slider>
          </div>

          {/* <div className="w-full flex justify-between items-center mt-8">
            <FeatureCard
              cardIcon={LanguageIcon}
              heading="Multilingual Support"
              text="Our health resources are available in multiple languages, ensuring everyone can access clear and accurate information regardless of their linguistic background."
            />

            <FeatureCard
              cardIcon={NoteIcon}
              heading="Real-time Simplification"
              text="By simplifying complex health information in real-time, our platform ensures users can easily understand and act on their health needs immediately"
            />

            <FeatureCard
              cardIcon={NoteIcon}
              heading="Real-time Simplification"
              text="By simplifying complex health information in real-time, our platform ensures users can easily understand and act on their health needs immediately"
            />

            <FeatureCard
              cardIcon={DatabaseIcon}
              heading="Easy, Seamless Sharing"
              text="Easily share simplified and/or translated medical information in a HIPAA-compliant, seamless patient portal or via direct print."
            />
          </div> */}
        </div>

        {/* <div className="my-16">
          <Slider {...settings}>
            <div style={{ width: "98%" }}>
              <img
                src={SliderImg}
                alt="Second slide"
                style={{ width: "100%", borderRadius: "10px" }}
              />
             
            </div>
            <div style={{ width: "2%" }}>
              <img
                src={SliderImg}
                alt="Second slide"
                style={{ width: "100%", borderRadius: "10px" }}
              />
            </div>
            
          </Slider>
        </div> */}
      </div>
    </>
  );
};

export default FeatureMain;
