import React, { useContext, useEffect, useRef, useState } from "react";
import DoctorSidebar from "../../Component/Doctor/Layout/DoctorSidebar";
import DoctorImage from "../../images/patientImage.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import ReportDetail from "../patient/dashboard/ReportDetail";
import GlobalStorage from "../../Storage/ContextProvider";
import { FaRegUserCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import LightLogo from "../../images/newLogo.png";

import ReactToPrint, { useReactToPrint } from "react-to-print";

const PatientDetail = () => {
  const params = useParams();
  const [reportDetail, setReportDetail] = useState("");
  const { patientDetail, setPatientDetail, doctorProfile } =
    useContext(GlobalStorage);
  const [toggle, setToggle] = useState(false);
  const [savePopup, setSavePopup] = useState(false);
  const ref = useRef();
  const navigate = useNavigate();
  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();
  const [hName, setHname] = useState("");

  useEffect(() => {
    hospitalDetail();
  }, []);

  const hospitalDetail = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/hospital/${doctorProfile?.hospitalId}`,
    })
      .then((response) => {
        console.log("Get HospitalProfile", response?.data);
        setHname(response?.data?.hospitalName);
      })
      .catch((error) => {
        console.log("Get Hospital  Detail ERROR", error.response);
      });
  };

  useEffect(() => {
    loadReport();
  }, [toggle]);

  const loadReport = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/report/details/${params?.reportId}`,
    })
      .then((response) => {
        console.log("Get pATIENT", response);
        setReportDetail(response.data);
      })
      .catch((error) => {
        console.log("Get Patient ERROR", error.response);
      });
  };

  const handleDeleteReport = () => {
    if (doctorProfile?.status === "InActive") {
      return toast.error("Account Deactivated");
    }
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this report?"
    );
    if (isConfirmed) {
      axios({
        method: "PUT",
        url: `${BASE_URL}/delete-doctor-notes/${params?.reportId}`,
      })
        .then((response) => {
          console.log("Delete Medical Report", response.data);
          toast.success("Report Deleted Successfully");
          setToggle(!toggle);
          navigate("/doctor/dashboard");
        })
        .catch((error) => {
          console.log("Delete Patient Notes Error", error.response);
        });
    }
  };
  const fData = (data) => {
    return data?.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const downloadDoc = (title) => {
    var header = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>${title}</title></head><body>`;

    var footer = "</body></html>";

    var html =
      header + document.getElementById("contentPad").innerHTML + footer;

    var blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });

    // Specify link url
    var url =
      "data:application/vnd. ms-word;charset=utf-8," + encodeURIComponent(html);

    // Create download link element
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, "Report-Document.docx");
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = "Report-Document.docx";

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSavePopup(false);
          // alert("You clicked outside of me!");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  // const wrapperRef = useRef(null);
  useOutsideAlerter(ref);
  return (
    <>
      <DoctorSidebar>
        <div
          className={`${
            savePopup
              ? "bg-[rgba(0,0,0,0.9)] opacity-[.04] dark:opacity-[.3] overflow-hidden h-[90vh]"
              : " text-[#052721] dark:text-[#fff]"
          } p-[20px]  `}
        >
          <div className="dark:bg-[var(--dark-primary)] bg-[#fff] w-[100%] h-[160px] flex justify-between items-center p-[20px]">
            <div className="flex items-center gap-[20px] ">
              {/* <img src={DoctorImage} className='w-[97px] h-[97px] rounded-full' alt="" /> */}
              <FaRegUserCircle className="text-[65px]" />
              <div>
                <p className="text-[25px] font-[500]">
                  Patient: {patientDetail?.name}
                </p>
                <p className="text-[15px] font-[400]">Record ID: #11323094</p>
                <p className="text-[15px] font-[400] dark:text-[#FFCFAA]">
                  Date Transcribed: 12th January, 2024
                </p>
                <p className="text-[15px] font-[400] dark:text-[#FFCFAA]">
                  Document Title: {reportDetail?.title}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-[25px] text-[#fff]">
              {/* <button className='w-[190px] h-[45px] bg-[#67A599] rounded-full font-inter font-[600] text-[14px] '>Export Transcript</button> */}
              <button
                className="w-[190px] h-[45px] bg-[#FF0000] rounded-full font-inter font-[600] text-[14px]"
                onClick={handleDeleteReport}
              >
                Delete Transcript
              </button>
            </div>
          </div>

          <div className="p-[20px]  ">
            <div className="w-[100%] flex items-center gap-[25px] my-[30px]">
              <div className="w-[50%]">
                <button className="w-full h-[53px]  bg-[var(--doctor-color)] text-[#fff] rounded-[20px] font-inter font-[700] text-[21px]">
                  Enter Data
                </button>
              </div>
              <div className="w-[50%]">
                <button className="w-full h-[53px] bg-[var(--doctor-color)] text-[#fff] rounded-[20px] font-inter font-[700] text-[21px]">
                  Transcribed Data
                </button>
              </div>
            </div>
            <p className="text-[15px] mb-5 w-full  p-[10px] outline-none bg-[#fff] font-[400] dark:text-[#FFCFAA]">
              <strong>Title: </strong> {reportDetail?.title}
            </p>
            <div className="w-[100%] flex item-center gap-[25px]">
              <div className="h-[300px] w-[50%] bg-[#fff] dark:bg-[var(--dark-primary)] p-[25px]  rounded-[20px]">
                <textarea
                  name=""
                  id=""
                  value={reportDetail?.originalReport}
                  className="  w-full max-h-[170px] min-h-[170px] p-[10px] bg-transparent  outline-none"
                  placeholder="input raw data here...."
                ></textarea>

                <div className="flex justify-end mt-[22px]">
                  <button
                    className="w-[165px] h-[50px] bg-[#67A599] dark:bg-[#FFCFAA] dark:text-[var(--dark-primary)] text-[#fff] text-[20px] font-inter font-[600] rounded-full "
                    style={{ boxShadow: "0px 4px 18px 0px #00000070" }}
                  >
                    Transcribe
                  </button>
                </div>
              </div>
              <div className="h-[300px] w-[50%] bg-[#fff] dark:bg-[var(--dark-primary)] p-[25px]  rounded-[20px]">
                <textarea
                  name=""
                  id=""
                  value={reportDetail?.transcribedText}
                  className="  w-full max-h-[170px] min-h-[170px] p-[10px] bg-transparent outline-none"
                  placeholder="Transcribe data shows here..."
                ></textarea>

                <div className="flex justify-end mt-[22px]">
                  <button
                    className="w-[165px] h-[50px] bg-[#DDBEA6] dark:bg-[#FFCFAA] dark:text-[var(--dark-primary)] text-[#fff] text-[20px] font-inter font-[600] rounded-full"
                    style={{ boxShadow: "0px 4px 18px 0px #00000070" }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
            <div className="w-[100%] flex items-center gap-[20px] font-inter font-[600] text-[21px] text-[#fff] my-[20px]">
              <button className="w-[60%] h-[50px]  bg-[var(--doctor-color)] rounded-[20px]">
                Translated Data
              </button>
              <button className="w-[25%] h-[50px] bg-[#67A599] opacity-[.25] rounded-[20px]">
                Select Language
              </button>
              <button className="w-[15%] h-[50px] bg-[#67A599] opacity-[.25] rounded-[20px]">
                Translate
              </button>
            </div>

            <div className="my-[10px]">
              <textarea
                value={reportDetail?.nativeLanguageTranscribedText}
                className=" w-[100%] h-[250px] bg-[#fff] dark:bg-[var(--dark-primary)] outline-none p-[20px] rounded-[20px]"
                placeholder="Transcribed data shows here"
              ></textarea>
            </div>
            <div className="my-[20px] flex items-center gap-[20px] font-inter font-[600] text-[21px] text-[#fff]">
              <button
                className="w-[40%] h-[50px] bg-[#DDBEA6]  dark:text-[var(--dark-primary)] rounded-full "
                style={{ boxShadow: "0px 4px 18px 0px #00000070" }}
                onClick={() => setSavePopup(true)}
              >
                Save
              </button>

              <button
                className="w-[60%] h-[50px] bg-[var(--doctor-color)]  opacity-[.25] rounded-full "
                style={{ boxShadow: "0px 4px 18px 0px #00000070" }}
              >
                Save & Share
              </button>
            </div>
          </div>
        </div>

        {savePopup && (
          <>
            <div className="absolute top-[150px] bg-white w-[950px] min-h-[200px] max-h-[500px] overflow-y-scroll  left-[28%] right-[20%] rounded-lg">
              <div ref={ref}>
                <div className="text-black p-[35px] relative  rounded-2xl">
                  <div id="contentPad" className="p-12 text-lg">
                    <div className="flex items-center justify-between mb-7 ">
                      <div className="">
                        <img
                          src={LightLogo}
                          className="w-[120px] h-auto"
                          alt=""
                        />
                      </div>
                      <div className=" flex items-center gap-1">
                        <p className="font-[500]">Hospital Name - </p>
                        <p className="font-[500] text-[26px]">{hName}</p>
                      </div>
                    </div>

                    <p>
                      Date - {currDate} {currTime}
                    </p>
                    <h2 className="text-xl mt-6 font-[600]">Medical Report:</h2>
                    {/* <p>{rawData}</p> */}
                    <p>{fData(reportDetail?.originalReport)}</p>

                    <h2 className="text-xl mt-6 font-[600]">
                      Simplified Report:
                    </h2>
                    {/* <p>{processedData}</p> */}

                    <p>{fData(reportDetail?.transcribedText)}</p>
                    <h2 className="text-xl mt-6 font-[600]">
                      Translated Report:
                    </h2>
                    <p>{fData(reportDetail?.nativeLanguageTranscribedText)}</p>

                    {/* <p>{translatedData}</p> */}
                  </div>
                  <div className="w-full flex items-center justify-center gap-6 mt-6 mb-6">
                    {/* <button
                    className="flex items-center justify-center bg-[var(--doctor-color)] text-white py-3 px-12 rounded-lg"
                    onClick={() => toPDF()}
                  >
                    Save
                  </button> */}
                    {/* <button
                      className="flex items-center justify-center bg-[#FF5252] text-white py-3 px-12 rounded-lg"
                      onClick={() => downloadDoc(reportDetail?.title)}
                    >
                      Download Docx
                    </button> */}
                    <ReactToPrint
                      pageStyle={{ margin: "15px" }}
                      content={() => ref.current}
                      trigger={() => (
                        <button
                          className="flex items-center justify-center bg-[#FF5252] text-white py-3 px-12 rounded-lg"
                          // onClick={() => setSavePopup(false)}
                        >
                          Save or Print
                        </button>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </DoctorSidebar>
    </>
  );
};

export default PatientDetail;
