import React, { useState } from "react";
import { FiSun } from "react-icons/fi";
import { MdOutlineDarkMode } from "react-icons/md";
import HomeCards from "../../../component/Pateint/HomeCards";
import { CiSearch } from "react-icons/ci";
import { HiArrowLongRight } from "react-icons/hi2";
import { BiEditAlt } from "react-icons/bi";
import { MdOutlineTranslate } from "react-icons/md";
import { BsUpload } from "react-icons/bs";
import { PiChatCenteredText } from "react-icons/pi";
import { CiMicrophoneOn } from "react-icons/ci";
import DoctorDashboardLayout from "../../../layout/doctor";
import SimplifyIcon from "../../../../images/simplify.svg";
import TranslateIcon from "../../../../images/translate.svg";
import { BsWallet2 } from "react-icons/bs";

import { FiUser } from "react-icons/fi";
import { CiWarning } from "react-icons/ci";

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSimplify, setIsSimplify] = useState(true);
  return (
    <DoctorDashboardLayout>
      <div className="flex items-center justify-between my-[10px] px-[10px]">
        <h2 className="text-3xl font-[500]">Dashboard</h2>
        <div className="flex flex-row items-center gap-3">
          <button className="w-[140px] ml-[10px] rounded-full text-white bg-primary flex items-center justify-center h-[45px]">
            <img
              className="w-[24px] h-[24px]"
              src={require("../../../../images/add-circle.png")}
              alt=""
            />
            <span className="ml-1 text-[12px] font-[500]">New Patient</span>
          </button>
          <div className="w-[260px] flex items-center bg-[#B0B0B0] h-[45px] rounded-full ">
            <div
              className={` ${
                isDarkMode ? "bg-[#B0B0B0] text-white" : "bg-white"
              }  w-1/2 h-[45px] flex items-center gap-1 justify-center rounded-full cursor-pointer`}
              onClick={() => setIsDarkMode(false)}
            >
              <FiSun
                className={`${!isDarkMode ? "text-grey" : "text-white"} `}
              />
              <p className="text-[13px] font-[500]">light mode</p>
            </div>
            <div
              className={` ${
                isDarkMode ? "bg-white" : "bg-[#B0B0B0] text-white"
              } w-1/2 h-[45px] flex items-center gap-1 justify-center rounded-full px-2 text-[13px] cursor-pointer`}
              onClick={() => setIsDarkMode(true)}
            >
              <MdOutlineDarkMode
                className={`${isDarkMode ? "text-grey" : "text-white"} `}
              />
              <p>dark mode</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-5 mt-[25px] px-[10px]">
        <div className="w-[40%] h-[200px] text-white bg-primary rounded-[20px] border-[.7px] border-primary p-[20px] py-[24px] flex flex-col justify-between ">
          <h2 className="text-[28px] font-[500]">Good Morning Dr Titobi</h2>
          <p className="text-lg ">How are you feeling today?</p>

          <div className="  mt-[10px]  flex justify-between">
            <button className="w-[165px] rounded-full text-black bg-white flex items-center justify-center h-[50px]">
              <img
                className="h-[24px] w-[24px] mr-1"
                src={require("../../../../images/add-circle-black.png")}
                alt=""
              />
              <p className="ml-1">New Patient</p>
            </button>
          </div>
        </div>
        <div className="w-[20%] h-[200px] bg-white rounded-[20px] border-[.7px] border-[#D7D7D7] px-[10px] py-[24px] flex flex-col items-center justify-between">
          <div className="flex items-center gap-2">
            <FiUser className="text-[24px] text-[#47BD68]" />
            <h2 className="text-lg font-[500]">Active Patients </h2>
          </div>

          <p className="font-semibold text-[50px] text-[#47BD68]">104</p>

          <button className="w-[180px] h-[40px] flex items-center justify-center border-[0.7px] border-[#B0B0B0] rounded-full text-grey font-[500]">
            <p className="mr-1 text-[13px]">View all patients</p>
            <HiArrowLongRight className="ml-1" />
          </button>
        </div>
        <div className="w-[20%] h-[200px] bg-white rounded-[20px] border-[.7px] border-[#D7D7D7] px-[10px] py-[24px] flex flex-col items-center justify-between">
          <div className="flex items-center gap-2">
            <CiWarning className="text-[24px] text-[#FFCC00]" />
            <h2 className="text-lg font-[500]">Pending Patients</h2>
          </div>

          <p className="font-semibold text-[50px] text-[#FFCC00]">3</p>

          <button className="w-[180px] h-[40px] flex items-center justify-center border-[0.7px] border-[#B0B0B0] rounded-full text-grey">
            <p className="mr-1 text-[13px] font-[500]">View pending mails</p>
            <HiArrowLongRight className="ml-1" />
          </button>
        </div>

        <div className="w-[20%] h-[200px]  bg-white rounded-[20px] border-[.7px] border-[#D7D7D7] px-[10px] py-[24px] flex flex-col items-center justify-between">
          <div className="flex items-center gap-2">
            <BsWallet2 className="text-[24px] text-[#FF2D55]" />
            <h2 className="text-lg font-[500]">All Transcripts</h2>
          </div>

          <p className="font-semibold text-[50px] text-[#FF2D55]">120</p>

          <button className="w-[180px] h-[40px] flex items-center font-[500] justify-center border-[0.7px] border-[#B0B0B0] rounded-full text-grey">
            <p className="mr-1 text-[13px]">View reports</p>
            <HiArrowLongRight className="ml-1" />
          </button>
        </div>
      </div>

      <div className="mt-10  px-[10px]">
        <h3 className="text-lg font-[500]">Recent</h3>
        <div className="flex w-full gap-4">
          <div className="w-[30%] ">
            <div className="flex items-center my-4  w-full h-[45px] border-[2px]  bg-[#fff] dark:bg-[var(--dark-primary)] rounded-[100px] relative text-[#000]">
              <CiSearch
                color="black"
                className="w-[24px] h-[24px]  absolute left-[10px]"
              />
              <input
                className=" w-full outline-none ml-[45px] mr-[10px] text-sm placeholder-[#7C7C7C]"
                type="text"
                placeholder="Find Report"
              />
            </div>
            {[1, 2, 3]?.map((item, index) => (
              <>
                <div className="p-[24px] border-[#D7D7D7] rounded-[20px] border-[0.7px] bg-white my-2">
                  <div className="flex items-center">
                    <div className="w-[60%] text-grey">
                      <p className="text-lg font-[500]">04-March-2024</p>
                      <p className="text-xs">9:45 PM</p>
                    </div>
                    <div className="w-[40%]">
                      <button className=" text-xs text-grey flex w-[145px] h-[40px] justify-center items-center rounded-full border-grey border-[.7px]">
                        <p className="mr-1">View transcript</p>
                        <HiArrowLongRight className="ml-1" />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>

          <div className="w-[70%]">
            <div className="my-4">
              <div className="w-[330px] flex items-center bg-[#515A9C1A] h-[45px] rounded-full ">
                <div
                  className={` ${
                    isSimplify ? "bg-primary text-white" : " text-grey"
                  }  w-1/2 h-[45px] flex items-center gap-1 justify-center rounded-full cursor-pointer`}
                  onClick={() => setIsSimplify(true)}
                >
                  <BiEditAlt
                    className={`${
                      isSimplify ? "text-white" : "text-grey"
                    } text-xl `}
                  />
                  <p className="text-[13px] font-[500]">Simplify</p>
                </div>
                <div
                  className={` ${
                    isSimplify ? " text-grey" : "bg-primary text-white"
                  } w-1/2 h-[45px] flex items-center gap-1 justify-center rounded-full px-2 text-[13px] cursor-pointer`}
                  onClick={() => setIsSimplify(false)}
                >
                  <MdOutlineTranslate
                    className={`${
                      isSimplify ? "text-grey" : "text-white"
                    } text-lg`}
                  />
                  <p>Translate</p>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <div className="w-1/3 bg-white border border-[#D7D7D7] h-[200px] rounded-[20px] flex justify-center items-center flex-col gap-2">
                <BsUpload className="text-[45px] text-grey" />
                <p className="text-lg font-[500]">Upload File</p>
              </div>

              <div className="w-1/3 bg-white border border-[#D7D7D7] h-[200px] rounded-[20px] flex justify-center items-center flex-col gap-2">
                <PiChatCenteredText className="text-[45px] text-grey" />
                <p className="text-lg font-[500]">Enter text</p>
              </div>

              <div className="w-1/3 bg-white border border-[#D7D7D7] h-[200px] rounded-[20px] flex justify-center items-center flex-col gap-2">
                <CiMicrophoneOn className="text-[45px] text-grey" />
                <p className="text-lg font-[500]">Record speech</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DoctorDashboardLayout>
  );
};

export default Dashboard;
