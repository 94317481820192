import React, { useContext } from "react";
import Header from "../Component/Patient/Header";
import AboutImage from "../images/about.svg";

import HomeFooter from "../Component/HomeFooter";
import HomeHeader from "../Component/HomeHeader";
import { FaArrowRightLong } from "react-icons/fa6";
import Question from "../Component/reusableCompo/Question";
import TestimonialSmall from "../Component/reusableCompo/TestimonialSmall";
import GlobalStorage from "../Storage/ContextProvider";

const About = () => {

  const {activeMobileNav} = useContext(GlobalStorage)
  return (
    <>
      <HomeHeader activeLink="about" />
     <div className={`${activeMobileNav ? "max-h-[calc(100vh-90px)] overflow-hidden": ""}`}>
     <div className="px-[100px]  max-[1300px]:px-[70px] max-[750px]:px-[40px] py-[60px] font-poppins">
        <div className="flex  w-full items-end gap-5 max-[1024px]:flex-col">
          <div className="w-[50%] max-[1024px]:w-full ">
            <h3 className="text-[22px] max-[750px]:text-lg max-[750px]:leading-[26px]  leading-[33px] text-primary">
              ABOUT US
            </h3>
            <h1 className="font-semibold text-[50px] max-[1200px]:text-[43px] max-[1024px]:text-[40px] max-[1024px]:leading-[65px]   max-[1200px]:leading-[67px] max-[750px]:text-[25px] max-[750px]:leading-[40px] leading-[75px] text-black">
              {/* <span className="text-grey">Breaking Down Barriers,</span>{" "} */}
              Better Communication For Better Outcomes
            </h1>
          </div>
          <div className="w-[50%] max-[1024px]:w-full ">
            <p className="text-[22px] max-[750px]:text-lg max-[750px]:leading-[26px] leading-[38px] text-black pr-6 max-[1024px]:pr-0">
              Provider-patient miscommunication can lead to poor health outcomes
              for patients and unnecessary inefficiencies in health Providers’
              workflow. Research has shown that over 50% of patients do not
              understand their doctor, and up to 80% of medical information is
              forgotten immediately.
            </p>
          </div>
        </div>
      </div>
      <img src={AboutImage} className="w-full" alt="" />

      <div className="px-[100px] max-[750px]:px-[40px] max-[1200px]:px-[70px] py-[80px] font-poppins">
        <h2 className="text-[50px] max-[1024px]:text-[40px] max-[1024px]:leading-[65px] leading-[75px] max-[750px]:text-[25px] max-[750px]:leading-[45px] font-semibold text-black pr-9">
          <span className="text-grey">Introducing</span>{" "}
          <span className="text-primary">MedInclude</span>{" "}
          <span className="text-grey">-</span> Bridging the Healthcare
          Communication Divide
        </h2>

        <div className="w-full flex items-center max-[1024px]:flex-col">
          <div className="w-[50%] max-[1024px]:w-full pr-[100px] max-[1024px]:pr-[0px] mt-6">
            <p className="text-[22px] leading-[33px] max-[750px]:text-lg text-black">
              Healthcare access and health outcomes should not be impacted by
              socio-economic class and other social determinants of health, but
              they are. Despite millions spent annually, existing solutions are
              often ad hoc, manual, and ineffective.
            </p>

            <p className="text-[22px] leading-[33px] max-[750px]:text-lg text-black mt-8">
              MedInclude bridges this gap by leveraging technology to simplify
              medical information into a grade 5 reading level and translate it
              into multiple languages at point of care. Providers increase
              efficiency, saving up to 90% of communication costs and 36
              hours/month, while patients access medical information in their
              preferred language.
            </p>
          </div>
          <div className="w-[50%] max-[1024px]:w-full flex justify-center items-center px-[50px] max-[1024px]:px-0 max-[1024px]:mt-5">
            <p className="text-[40px] max-[1024px]:text-[31px] leading-[50px] max-[750px]:text-[26px] max-[750px]:leading-[40px] text-primary">
              "We aim to Empower healthcare providers and patients through clear
              communication, accessible to all, regardless of language or
              literacy level."
            </p>
          </div>
        </div>

        <Question />
      </div>
      <div className="mb-[80px]">
        <TestimonialSmall />
      </div>

      <HomeFooter />
     </div>
    </>
  );
};

export default About;
