import React from "react";
import TestimonialCard from "./TestimonialCard";
import TestmonialBg from "../../../images/landing/TestimonialBackground.png";

const Testmonial = () => {
  return (
    <>
      <div
        className=" px-[100px] max-[1200px]:px-[70px] max-[750px]:px-[40px] font-poppins py-[50px] bg-no-repeat bg-cover mt-[80px] !overflow-x-hidden   "
        style={{
          backgroundImage: `url(${TestmonialBg})`,
        }}
      >
        <h2 className="text-[30px] leading-[45px] text-center  font-semibold">
          Testimonials
        </h2>

        <div className="flex justify-between flex-wrap gap-3 items-center mt-7 ">
          <TestimonialCard
            name="Brendan Wylie-Toal"
            position="Director of Innovation and Research"
            hospitalName="KIDSABILITY"
            desc="By introducing a method to transcribe and translate written information in real-time, we significantly improved equity in the services we provide to our families. Ultimately, this will lead to a more inclusive and enhanced experience for all"
          />
          <TestimonialCard
            name="Wajma Attayi"
            position="Director of Primary Care"
            hospitalName="COMMUNITY HEALTHCARING KW"
            desc="This is the best thing we’ve done for our patients in a long time."
          />
          <TestimonialCard
            name="Moazam Khan"
            position="Director"
            hospitalName="VELOCITY HEALTH"
            desc="Effective communication between patients and health-care providers is crucial for high-quality care. By bridging language barriers, MedInclude and health-care providers are setting a new standard for patient-centered care, ensuring more inclusive and compassionate health care."
          />
        </div>
      </div>
    </>
  );
};

export default Testmonial;
