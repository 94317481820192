import React, { useContext } from "react";
import GlobalStorage from "../../../Storage/ContextProvider";

function EditCustomPopup({ isOpen, children }) {
  const { isCollapsed, setIsCollapsed } = useContext(GlobalStorage);

  if (!isOpen) return null;

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: isCollapsed ? "64px" : "256px", // Use isCollapsed here
      width: isCollapsed ? "95vw" : "85vw",
      height: "100vh",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    popup: {},
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.popup}>
        <div>{children}</div>
      </div>
    </div>
  );
}

export default EditCustomPopup;
