import React from "react";
import FeatuedImage from "../../images/landing/blogImage.svg";
import { Link } from "react-router-dom";

const BlogCard = () => {
  return (
    <div className="w-[30%] max-[1023px]:w-[46%] max-[750px]:w-full mb-5 ">
      <div
        className="w-full  flex-col justify-center    rounded-[20px] bg-[#EFEBEB] border-b border-[#EFEBEB] "
        style={{ boxShadow: " 0px 0px 10px 0px #00000026" }}
      >
        <img src={FeatuedImage} className="w-full h-auto bg-cover" alt="" />
        <div className="p-[20px] pb-7">
          <h2 className=" font-bold text-[20px] leading-[28px] my-2">
            <Link to="/resources/blog/1">
              Blog Post 101 - The Art of Storytelling
            </Link>
          </h2>
          <div className="flex items-center text-[12px] leading-[22px] text-grey gap-6">
            <p>12/2/2024</p>
            <div className="flex items-center gap-1">
              <div className="w-1 h-1 rounded-full bg-grey"></div>
              <p>4min</p>
            </div>
          </div>

          <p className="text-grey text-sm leading-[22px] mt-4">
            This world is built on blood, nobody is a saint here.. Welcome to
            the badlands..
            <br /> <br />I have heard stories, there are places..
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
