import React from "react";
import Slider from "react-slick";
import BlogCover from "../../images/blogCover.png";

const BlogCaresol = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 cards
    slidesToScroll: 1,
    centerMode: true, // Center the active card
    centerPadding: "0", // Remove side padding
    focusOnSelect: true,
    // Start at the center slide
    responsive: [
      {
        breakpoint: 1024, // Mobile responsiveness
        settings: {
          slidesToShow: 2,
          centerMode: true,
        },
      },

      {
        breakpoint: 750, // Mobile responsiveness
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  };
  const blogPosts = [
    {
      title: "Blog Post 101 - The Art of Storytelling",
      date: "12/2/2024",
      time: "4 min",
    },
    {
      title: "Blog Post 102 - Journey to the Unknown",
      date: "12/4/2024",
      time: "5 min",
    },
    {
      title: "Blog Post 103 - Adventure Awaits",
      date: "12/6/2024",
      time: "6 min",
    },
    {
      title: "Blog Post 103 - Adventure Awaits",
      date: "12/6/2024",
      time: "6 min",
    },
  ];
  return (
    <>
      <div className="mt-[80px]">
        <h3 className="text-[24px] leading-[36px] text-center font-[500] mb-4">
          MUST READ
        </h3>
        <Slider {...settings}>
          {blogPosts.map((post, index) => (
            <div key={index} className="font-poppins w-full  ">
              <div className="rounded-[20px] slickOver ">
                <div className=" carousel-card   rounded-[20px]  bg-[#EFEBEB]  ">
                  <div
                    className=" bg-white  h-[236px] bg-cover flex px-7 items-end "
                    style={{
                      background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 31%, rgba(0, 0, 0, 0.8) 77.5%), url(${BlogCover})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="carousel-content max-[750px]:px-3 ">
                      <h3 className="font-bold text-[20px] text-start leading-[26px] text-white">
                        {post.title}
                      </h3>
                      <div className="mt-2 mb-4 flex items-center gap-5">
                        <p className="text-sm text-[#D7D7D7]   ">{post.date}</p>
                        <p className="text-sm text-[#D7D7D7] ">• {post.time}</p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <p className="mt-4 text-sm text-grey mx-9 pb-2 leading-[25px]">
                      This world is built on blood, nobody is a saint here..
                      Welcome to the badlands..
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default BlogCaresol;
