import React, { useEffect, useState } from "react";
import DoctorSidebar from "../../../Component/Doctor/Layout/DoctorSidebar";
import axios from "axios";
import { getCookie } from "../../../auth/helper";
import BASE_URL from "../../../baseUrl";

const Notifications = () => {
  const token = getCookie("token");
  const [hasLoaded, SetHasLoaded] = useState(false);
  const [helpNotification, setHelpNotification] = useState(false);
  const [transcriptionNotification, setTranscriptionNotification] =
    useState(false);
  const [newsletterNotification, setNewsletterNotification] = useState();
  const [updateNotification, setUpdateNotification] = useState(false);
  const [tutorialNotification, setTutorialNotification] = useState(false);

  const saveSettings = (data) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/settings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    })
      .then((response) => {
        console.log("Settings saved");
        // setHelpNotification(response?.data?.helpNotification);
        // setTranscriptionNotification(response?.data?.transcriptionNotification);
        // setNewsletterNotification(response?.data?.newsletterNotification);
        // setUpdateNotification(response?.data?.updateNotification);
        // setTutorialNotification(response?.data?.tutorialNotification);
      })
      .catch((error) => {
        console.log("Save settings ERROR", error.response);
      });
  };

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (hasLoaded) {
      console.log("fired savesettings");
      const data = {
        helpNotification,
        transcriptionNotification,
        newsletterNotification,
        updateNotification,
        tutorialNotification,
      };
      saveSettings(data);
    }
  }, [
    helpNotification,
    transcriptionNotification,
    newsletterNotification,
    updateNotification,
    tutorialNotification,
  ]);

  const getSettings = () => {
    axios({
      method: "GET",
      url: `${BASE_URL}/settings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log("Get settings", response);
        setHelpNotification(response?.data?.helpNotification);
        setTranscriptionNotification(response?.data?.transcriptionNotification);
        setNewsletterNotification(response?.data?.newsletterNotification);
        setUpdateNotification(response?.data?.updateNotification);
        setTutorialNotification(response?.data?.tutorialNotification);
        setTimeout(() => {
          SetHasLoaded(true);
        }, 500);
      })
      .catch((error) => {
        console.log("Get settings ERROR", error.response);
      });
  };

  return (
    <DoctorSidebar activeLink="Notifications">
      <div className="p-[20px] text-[#052721] dark:text-[#fff] ">
        <div className=" border-b border-[var(--doctor-color)] dark:text-[#fff] pb-[15px]">
          <div>
            <h2 className="font-[500] text-[25px]">Notificatons</h2>
            <p className="font-[300] text-[16px] text-[var(--doctor-color)] dark:text-[#fff]">
              Indicate how you’ll like to be notified
            </p>
          </div>
        </div>
        <div className="px-[15px] py-[15px]">
          <h2 className=" text-[20px] font-[500]">Internal</h2>
          <p className="font-[300] py-[15px] text-[16px]  w-[40%]">
            Set how you’ll like to receive notification from the users of the
            platform within the organization
          </p>
        </div>
        <div>
          <div className="flex items-center py-[15px]">
            <input
              type="checkbox"
              name="business"
              checked={helpNotification}
              onChange={(e) => setHelpNotification(e.target.checked)}
              className="notyInput"
            />
            <label className="text-[20px]  ml-[20px]">
              Help and Assistance
            </label>
          </div>
          <div className="flex items-center py-[15px]">
            <input
              type="checkbox"
              name="economy"
              checked={transcriptionNotification}
              onChange={(e) => setTranscriptionNotification(e.target.checked)}
              className="notyInput"
            />
            <label className="text-[20px]  ml-[20px]">
              Transcription Report Alert
            </label>
          </div>
        </div>

        <div className="px-[15px] py-[25px]">
          <h2 className=" text-[20px] font-[500]">External</h2>
          <p className="font-[300] py-[15px] text-[16px]  w-[40%]">
            Set how you’ll like to receive notification from Medinclude
          </p>
        </div>
        <div>
          <div className="flex items-center py-[15px]">
            <input
              type="checkbox"
              name="business"
              checked={newsletterNotification}
              onChange={(e) => setNewsletterNotification(e.target.checked)}
              className="notyInput"
            />
            <label className="text-[20px] ml-[20px]">Newsletter</label>
          </div>
          <div className="flex items-center py-[15px]">
            <input
              type="checkbox"
              name="economy"
              checked={updateNotification}
              onChange={(e) => setUpdateNotification(e.target.checked)}
              className="notyInput"
            />
            <label className="text-[20px] ml-[20px]">Updates</label>
          </div>
          <div className="flex items-center py-[15px]">
            <input
              type="checkbox"
              name="economy"
              checked={tutorialNotification}
              onChange={(e) => setTutorialNotification(e.target.checked)}
              className="notyInput"
            />
            <label className="text-[20px] ml-[20px]">Tips and Tutorials</label>
          </div>
          {/* <div className="flex items-center py-[15px]">
            <input type="checkbox" name="economy"  className="notyInput" />
            <label className="text-[20px]  ml-[20px]">Offers and Promotions</label>
          </div> */}
        </div>
      </div>
    </DoctorSidebar>
  );
};

export default Notifications;
