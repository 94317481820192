import React, { useContext } from "react";
import HomeHeader from "../Component/HomeHeader";
import { FaArrowLeftLong } from "react-icons/fa6";
import BlogHome from "../images/blogCover.png";
import HomeFooter from "../Component/HomeFooter";
import GlobalStorage from "../Storage/ContextProvider";

const BlogDetail = () => {
  const { activeMobileNav } = useContext(GlobalStorage);

  return (
    <>
      <HomeHeader activeLink="resources" />
      <div
        className={`${
          activeMobileNav ? "max-h-[calc(100vh-90px)] overflow-hidden" : ""
        }`}
      >
        <div className="pt-[40px]  mb-[80px]  font-poppins">
          <div className="px-[100px] max-[1300px]:px-[70px] max-[750px]:px-[40px] mb-4">
            <div className="flex items-center gap-3 ">
              <FaArrowLeftLong className="text-[#141B34]" />
              <p className="text-[22px]  leading-[33px] text-grey">
                DECEMBER 12. 2024
              </p>
            </div>
            <h1 className="text-[50px] leading-[75px] max-[1024px]:text-[32px] max-[1024px]:leading-[57px] max-[750px]:text-[28px] max-[750px]:leading-[45px] font-semibold">
              BLOGPOST 101: THE ART OF STORYTELLING
            </h1>
          </div>

          <img
            src={BlogHome}
            className="w-full h-[500px] object-cover"
            alt=""
          />
          <div className="px-[100px] max-[1300px]:px-[70px] max-[750px]:px-[40px] mt-9">
            <p className="text-[22px] max-[750px]:text-lg leading-[33px]">
              Duis sem metus, blandit ac leo non, cursus interdum leo. Praesent
              semper elit a nunc rutrum, iaculis congue nibh interdum. Morbi in
              ligula a diam cursus porttitor vel et turpis. Aliquam maximus
              mollis sem, ac posuere mi ornare aliquam. Quisque dignissim
              bibendum commodo.
              <br /> <br />
              Maecenas at erat at metus condimentum viverra. Morbi nisl ligula,
              tristique a odio ut, varius dictum mauris. Etiam fringilla mauris
              odio, et vulputate arcu egestas non. Orci varius natoque penatibus
              et magnis dis parturient montes, nascetur ridiculus mus.
              Pellentesque quis nunc auctor, facilisis massa vitae, tristique
              sapienDuis sem metus, blandit ac leo non, cursus interdum leo.
              <br /> <br />
              Praesent semper elit a nunc rutrum, iaculis congue nibh interdum.
              Morbi in ligula a diam cursus porttitor vel et turpis. Aliquam
              maximus mollis sem, ac posuere mi ornare aliquam. Quisque
              dignissim bibendum commodo.
              <br /> <br />
              Maecenas at erat at metus condimentum viverra. Morbi nisl ligula,
              tristique a odio ut, varius dictum mauris. Etiam fringilla mauris
              odio, et vulputate arcu egestas non. Orci varius natoque penatibus
              et magnis dis parturient montes, nascetur ridiculus mus.
              Pellentesque quis nunc auctor, facilisis massa vitae, tristique
              sapien..
            </p>
          </div>
        </div>
        <HomeFooter />
      </div>
    </>
  );
};

export default BlogDetail;
