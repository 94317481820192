import React from "react";
import PatientDashboard from "../../../layout/patient/index";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsUpload } from "react-icons/bs";
import { TbFolderUp } from "react-icons/tb";
import { IoPrintOutline } from "react-icons/io5";
import { GrDocumentDownload } from "react-icons/gr";
import { CiMicrophoneOn } from "react-icons/ci";
import { BiEditAlt } from "react-icons/bi";
import { MdOutlineTranslate } from "react-icons/md";
import { RxSpeakerLoud } from "react-icons/rx";

const Simplify = () => {
  return (
    <PatientDashboard>
      <div className="w-full flex gap-8 ">
        <div className="w-[40%]">
          <div className="flex items-center mb-8">
            <h2 className="text-3xl font-[500]">Dashboard</h2>
            <div className="flex items-center mt-[2px] ">
              <MdKeyboardArrowRight className=" text-[#B0B0B0]" size={25} />
              <MdKeyboardArrowRight
                size={32}
                className="-ml-[19px] text-grey"
              />
            </div>
            <h3 className="text-lg font-[500]">Simplify</h3>

            <div className="flex items-center mt-[2px] ">
              <MdKeyboardArrowRight className=" text-[#B0B0B0]" size={25} />
              <MdKeyboardArrowRight
                size={32}
                className="-ml-[19px] text-grey"
              />
            </div>
          </div>

          <div className="">
            <p className="text-lg font-[500]">Title</p>
            <input
              type="text"
              className="py-[12px] w-full outline-none rounded-[20px] border border-[#D7D7D7] px-[35px] mt-3"
              placeholder="Ulceric Test Results"
            />
          </div>

          <div className="mt-6">
            <p className="text-lg font-[500] mb-2">Medical Note</p>
            <textarea
              name=""
              id=""
              className="py-[35px] w-full outline-none rounded-[20px] border
              border-[#D7D7D7] px-[35px] min-h-[500px] max-h-[500px] "
              placeholder="Enter note to simplify"
            ></textarea>
          </div>

          <div className="flex justify-end gap-2 items-center mt-2">
            <button className="w-[50px] h-[50px] rounded-full bg-primary text-white flex items-center justify-center">
              <TbFolderUp className="text-[22px]" />
            </button>

            <button className="w-[50px] h-[50px] rounded-full bg-[#FF3B30] text-white flex items-center justify-center">
              <CiMicrophoneOn className="text-[24px]" />
            </button>

            <button className="border-[.7px] border-[#EFEBEB] rounded-full w-[215px] flex items-center justify-center h-[50px] bg-secondary text-white text-[16px] font-semibold gap-2">
              <BiEditAlt size={23} />
              <p>Simplify</p>
            </button>
          </div>
        </div>

        <div className="w-[40%] ">
          <div className="w-full rounded-[20px] bg-white h-[160px] p-[24px] relative">
            <div className="flex  gap-3">
              <div className="w-[80%]">
                <h3 className=" text-2xl font-[500]">Malik Marshall</h3>
                <p className="text-xs">mmarshall@gmail.com</p>
              </div>

              <div className="w-[20%]">
                <button className="flex items-center gap-1 border-[.7px] border-[#47BD68] px-[16px] py-[5px] rounded-full bg-[#47BD681A] text-xs font-[500] text-[#47BD68]">
                  <div className="w-[5px] h-[5px] rounded-full bg-[#47BD68]"></div>
                  <p>Active</p>
                </button>
              </div>
            </div>

            <button className="flex gap-3 absolute bottom-[24px] border-[.7px] border-[#EFEBEB] px-[16px] py-[10px] rounded-full">
              <TbFolderUp className="text-grey" />
              <p className="text-xs font-[500]">View Transcripts</p>
            </button>
          </div>

          <div className="mt-6 relative">
            <p className="text-lg font-[500] mb-2">Simplified Note</p>
            <textarea
              name=""
              id=""
              className="py-[35px] w-full outline-none rounded-[20px] border
              border-[#D7D7D7] px-[35px] min-h-[500px] max-h-[500px] "
              placeholder="Simplified note to show up here.."
            ></textarea>

            <div className="w-[35px] h-[35px] rounded-full bg-secondary flex items-center justify-center absolute bottom-[40px] right-[30px]">
              <RxSpeakerLoud className="text-white text-[18px]" />
            </div>
          </div>
        </div>

        <div className="w-[20%]">
          <div className="absolute top-[50%]">
            <div className="flex items-center gap-2 my-5">
              <div className="w-[50px] h-[50px] rounded-full bg-primary flex items-center justify-center ">
                <GrDocumentDownload className="text-white text-[23px]" />
              </div>
              <p className="text-[16px] font-[500]">
                Save & <br /> Download
              </p>
            </div>

            <div className="flex items-center gap-2 my-5">
              <div className="w-[50px] h-[50px] rounded-full bg-primary flex items-center justify-center ">
                <IoPrintOutline className="text-white text-[23px]" />
              </div>
              <p className="text-[16px] font-[500]">Print</p>
            </div>

            <div className="flex items-center gap-2 my-5">
              <div className="w-[50px] h-[50px] rounded-full bg-[#FFCC00] flex items-center justify-center ">
                <MdOutlineTranslate className="text-black text-[23px]" />
              </div>
              <p className="text-[16px] font-[500]">Translate</p>
            </div>
          </div>
        </div>
      </div>
    </PatientDashboard>
  );
};

export default Simplify;
