import React, { useContext } from "react";
import HomeHeader from "../Component/HomeHeader";
import BlogHome from "../images/blohHome.png";
import BlogCaresol from "../Component/Blog/BlogCaresol";
import FeatureCard from "../Component/LandingPage/Feature/FeatureCard";
import BlogCard from "../Component/Blog/BlogCard";
import BlogCurious from "../Component/Blog/BlogCurious";
import HomeFooter from "../Component/HomeFooter";
import GlobalStorage from "../Storage/ContextProvider";

const Blog = () => {
  const { activeMobileNav } = useContext(GlobalStorage);
  return (
    <>
      <HomeHeader activeLink="resources" />
      <div
        className={`${
          activeMobileNav ? "max-h-[calc(100vh-90px)] overflow-hidden" : ""
        }`}
      >
        <div className="px-[100px] max-[1300px]:px-[70px] max-[750px]:px-[40px]  py-[60px] font-poppins">
          <div className="flex w-full justify-between gap-10 max-[750px]:flex-col">
            <div className="w-[50%]   max-[1024px]:max-[55%] max-[750px]:w-full ">
              <h3 className="text-[22px]  max-[750px]:text-lg max-[750px]:leading-[26px] leading-[33px] text-primary">
                BLOG
              </h3>
              <h2 className="text-[50px] leading-[75px]  max-[1024px]:text-[32px] max-[1024px]:leading-[57px] max-[750px]:text-[28px] max-[750px]:leading-[45px] font-semibold ">
                READ OUR <span className="text-primary">FEATURED ARTICLES</span>{" "}
                IN THE WORLD OF HEALTH
              </h2>
            </div>

            <div className="w-[50%]  max-[1024px]:w-[45%] max-[750px]:w-full">
              <img src={BlogHome} alt="" />
            </div>
          </div>

          <BlogCaresol />

          <div className="mt-[80px]">
            <h3 className="text-[24px] leading-[36px] text-center font-[500] mb-4">
              ALL ARTICLES
            </h3>

            <div className="flex items-center justify-between gap-[40px] flex-wrap">
              <BlogCard />
              <BlogCard />
              <BlogCard />
              <BlogCard />
              <BlogCard />
              <BlogCard />
            </div>
          </div>
        </div>
        <BlogCurious />

        <HomeFooter />
      </div>
    </>
  );
};

export default Blog;
