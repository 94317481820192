import React from "react";
import HeroBg from "../../images/landing/hero.svg";
import { FaArrowRightLong } from "react-icons/fa6";

const HeroNew = () => {
  return (
    <>
      <div className="relative">
        <div
          className="w-full h-[calc(100vh-90px)] px-[100px] max-[1200px]:px-[70px] max-[750px]:px-[40px]  font-poppins relative bg-no-repeat bg-cover bg-center max-[750px]:bg-center max-[750px]:bg-cover"
          style={{
            backgroundImage: `url(${HeroBg})`,
          }}
        >
          <div className="pt-[100px] min-[1920px]:pt-[150px] max-[1024px]:pt-[40px] z-10">
            <h1 className="text-[54px] max-[750px]:text-2xl max-[1350px]:text-[48px] leading-[61px] min-[1920px]:leading-[75px] max-[1024px]:text-[32px] max-[1024px]:leading-[40px] text-primary font-semibold">
              Your Trusted Partner
            </h1>
            <h1 className="text-[54px] max-[1350px]:text-[48px] max-[750px]:text-2xl  leading-[61px] min-[1920px]:leading-[75px] max-[1024px]:text-[32px] max-[1024px]:leading-[40px] text-primary font-semibold">
              For Patient Communication
            </h1>

            <p className="w-[40%] max-[1200px]:w-[60%] max-[750px]:w-full mt-1 max-[750px]:text-lg text-[24px] max-[1350px]:text-[21px] leading-[36px] min-[1920px]:leading-[43px] max-[1024px]:text-[19px] max-[1024px]:leading-[34px] text-white font-[500]">
              Healthcare communication made easy — Simplify, Translate, Share
            </p>

            <button className="flex bg-white gap-2 items-center p-[3px] rounded-full mt-8 ">
              <div className="w-[50px] h-[50px] rounded-full flex justify-center items-center bg-secondary text-white">
                <FaArrowRightLong size={22} />
              </div>
              <p className="pr-6 text-[20px] leading-[30px] max-[750px]:text-lg font-semibold">
                Book Demo
              </p>
            </button>
          </div>

          <div className="hidden max-[750px]:flex flex-col text-white mt-9 gap-4 absolute bottom-4">
            <div className="flex items-center gap-4">
              <p className="font-semibold text-xl  ">
                10 <span className="-ml-1 font-normal text-[15px]">+</span>
              </p>
              <p className="text-[15px] font-semibold">Languages supported</p>
            </div>
            <div className="flex items-center gap-4">
              <p className="font-semibold text-xl  ">
                60 <span className="-ml-1 font-normal text-[15px]">s</span>
              </p>
              <p className="text-[15px] font-semibold">Simplification Time</p>
            </div>

            <div className="flex items-center gap-4">
              <p className="font-semibold text-xl  ">
                98 <span className="-ml-1 font-normal text-[15px]">%</span>
              </p>
              <p className="text-[15px] font-semibold">Satisfaction rate</p>
            </div>
          </div>
        </div>

        <div
          className="absolute bottom-0 left-0 w-full h-[165px] z-0 max-[750px]:hidden "
          style={{
            background:
              " linear-gradient(0deg, rgba(0, 0, 0, 0.32) 18.61%, rgba(102, 102, 102, 0.32) 100%)",
            backdropFilter: " blur(16.799999237060547px)",
          }}
        >
          <div className=" w-full pt-4 max-[1024px]:px-[25px] flex justify-around items-center text-center max-[1024px]:justify-between  z-20 font-poppins text-white">
            <div className="max-[1024px]:w-[32%]">
              <p className="text-[70px] max-[1350px]:text-[55px] leading-[80px] font-semibold max-[1024px]:text-[30px] max-[1024px]:leading-[50px] ">
                60{" "}
                <span className="text-[40px] max-[1350px]:text-[36px] leading-[60px] font-normal max-[1024px]:text-[20px] max-[1350px]:-ml-4 -ml-5 max-[1024px]:-ml-2">
                  s
                </span>
              </p>
              <p className="text-center text-[20px] leading-[30px] font-semibold max-[1024px]:text-[16px] max-[1024px]:leading-[25px]">
                Simplification Time
              </p>
            </div>

            <div className="max-[1024px]:w-[32%]">
              <p className="text-[70px] leading-[80px] font-semibold max-[1350px]:text-[55px] max-[1024px]:text-[30px] max-[1024px]:leading-[50px] ">
                10{" "}
                <span className="text-[40px] max-[1350px]:text-[36px] leading-[60px] max-[1024px]:text-[20px] font-normal max-[1350px]:-ml-4 -ml-5 max-[1024px]:-ml-2">
                  +
                </span>
              </p>
              <p className="text-center text-[20px] leading-[30px] font-semibold max-[1024px]:text-[16px] max-[1024px]:leading-[25px]">
                Languages supported
              </p>
            </div>

            <div className="max-[1024px]:w-[32%]">
              <p className="text-[70px] leading-[80px] font-semibold max-[1350px]:text-[55px] max-[1024px]:text-[30px] max-[1024px]:leading-[50px] ">
                98{" "}
                <span className="text-[40px] max-[1350px]:text-[36px] max-[1024px]:text-[20px] leading-[60px] font-normal max-[1350px]:-ml-4 -ml-5 max-[1024px]:-ml-2 ">
                  %
                </span>
              </p>
              <p className="text-center text-[20px] leading-[30px] font-semibold w-[400px] max-[1024px]:w-full max-[1024px]:text-[16px] max-[1024px]:leadng-[25px]">
                98% satisfaction from providers who have used our platform.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroNew;
