import React from "react";
import { FaStar } from "react-icons/fa6";

const TestimonialCard = ({ name, position, desc, hospitalName }) => {
  return (
    <>
      <div
        className="w-[30%] max-[1024px]:mb-9  max-[1024px]:w-[49%] max-[750px]:w-full h-[400px]  bg-primary text-[rgba(255,255,255,0.9)] border border-[#EFEBEB80] p-[25px] rounded-[30px] rounded-tl-none"
        style={{
          boxShadow: "0px 0px 17.3px 0px #00000026",
          backdropFilter: "blur(14.800000190734863px)",
        }}
      >
        <h2
          className=" mt-6 font-bold text-[20px] leading-[22px] bg-clip-text text-transparent mb-1 "
          style={{
            backgroundImage:
              "linear-gradient(270deg, #F34E4E 0%, #BAEDBD 100%)",
          }}
        >
          {name}
        </h2>
        <p className="font-semibold text-[14px] leading-[22px] my-1">
          {position}
        </p>
        <p className="font-[500] text-[14px] leading-[22px]">{hospitalName}</p>

        <div className="flex items-center pt-6 pb-4">
          <FaStar className="text-[#FF9500]" size={23} />
          <FaStar className="text-[#FF9500]" size={23} />
          <FaStar className="text-[#FF9500]" size={23} />
          <FaStar className="text-[#FF9500]" size={23} />
          <FaStar className="text-[#FF9500]" size={23} />
        </div>
        <p className="text-[14px] leading-[22px]">{desc}</p>
      </div>
    </>
  );
};

export default TestimonialCard;
