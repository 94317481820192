import React, { useState } from "react";
import { FiSun } from "react-icons/fi";
import { MdOutlineDarkMode } from "react-icons/md";
import HomeCards from "../../../component/Pateint/HomeCards";
import { CiSearch } from "react-icons/ci";
import { HiArrowLongRight } from "react-icons/hi2";
import { BiEditAlt } from "react-icons/bi";
import { MdOutlineTranslate } from "react-icons/md";
import { BsUpload } from "react-icons/bs";
import { PiChatCenteredText } from "react-icons/pi";
import { CiMicrophoneOn } from "react-icons/ci";
import DoctorDashboardLayout from "../../../layout/doctor";
import SimplifyIcon from "../../../../images/simplify.svg";

const PatientDetailsV2 = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSimplify, setIsSimplify] = useState(true);
  const [showTile, setShowTile] = useState(true);

  const data = [
    {
      id: 1,
      name: "John Doe",
      email: "john@example.com",
      role: "Admin",
      status: "Active",
    },
    {
      id: 2,
      name: "Jane Smith",
      email: "jane@example.com",
      role: "Editor",
      status: "Pending",
    },
    {
      id: 3,
      name: "Mike Johnson",
      email: "mike@example.com",
      role: "Viewer",
      status: "Inactive",
    },
    {
      id: 4,
      name: "Emily Brown",
      email: "emily@example.com",
      role: "Admin",
      status: "Active",
    },
    {
      id: 5,
      name: "Chris Green",
      email: "chris@example.com",
      role: "Editor",
      status: "Pending",
    },
  ];

  return (
    <DoctorDashboardLayout>
      <div className="flex items-center justify-between mb-4 ">
        <div className="flex flex-row items-center">
          <h2 className="text-3xl ml-[10px] font-[500]">Patients</h2>
          <div className="flex items-center ml-[25px] w-full h-[45px] border-[2px]  bg-[#fff] dark:bg-[var(--dark-primary)] rounded-[100px] relative text-[#000]">
            <CiSearch
              color="black"
              className="w-[24px] h-[24px]  absolute left-[10px]"
            />
            <input
              className=" w-full outline-none ml-[45px] mr-[10px] placeholder-[#7C7C7C]"
              type="text"
              placeholder="Find Patient"
            />
          </div>
        </div>
        <div className="flex flex-row">
          <button
            onClick={() => setShowTile(true)}
            className=" px-[15px] mx-[10px] rounded-full text-white bg-white flex items-center justify-center h-[45px]"
          >
            <img
              className="w-[20px] h-[20px]"
              src={require("../../../../images/grid-view.png")}
              alt=""
            />
          </button>

          <button
            onClick={() => setShowTile(false)}
            className=" px-[15px] mx-[10px] rounded-full text-white bg-white flex items-center justify-center h-[45px]"
          >
            <img
              className="w-[20px] h-[20px]"
              src={require("../../../../images/p-menu.png")}
              alt=""
            />
          </button>

          <button className=" px-[15px] mx-[10px] rounded-full text-white bg-white flex items-center justify-center h-[45px]">
            <img
              className="w-[18px] h-[18px]"
              src={require("../../../../images/sort.png")}
              alt=""
            />
            <span className="ml-1 text-[12px] text-black font-[500]">Sort</span>
          </button>

          <button className="w-[140px] ml-[10px] rounded-full text-white bg-primary flex items-center justify-center h-[45px]">
            <img
              className="w-[24px] h-[24px]"
              src={require("../../../../images/add-circle.png")}
              alt=""
            />
            <span className="ml-1 text-[12px] font-[500]">New Patient</span>
          </button>
        </div>
      </div>
      <div className="p-8 mt-16 mb-[30px] bg-white rounded-[20px]">
        <form className="grid grid-cols-1 gap-x-20 gap-y-6 md:grid-cols-2">
          {/* Column 1 */}
          <div>
            <label
              htmlFor="firstName"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your first name"
            />
          </div>

          <div>
            <label
              htmlFor="lastName"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your last name"
            />
          </div>

          {/* Column 2 */}
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your email"
            />
          </div>

          <div className="flex flex-row items-end">
            <div className="flex flex-row">
              <button
                onClick={() => setShowTile(true)}
                className=" px-[15px] mx-[10px] rounded-full text-white bg-white flex items-center justify-center h-[45px]"
              >
                <img
                  className="w-[20px] h-[20px]"
                  src={require("../../../../images/grid-view.png")}
                  alt=""
                />
              </button>

              <button
                onClick={() => setShowTile(false)}
                className=" px-[15px] mx-[10px] rounded-full text-white bg-white flex items-center justify-center h-[45px]"
              >
                <img
                  className="w-[20px] h-[20px]"
                  src={require("../../../../images/p-menu.png")}
                  alt=""
                />
              </button>

              <button className=" px-[15px] mx-[10px] rounded-full text-white bg-white flex items-center justify-center h-[45px]">
                <img
                  className="w-[18px] h-[18px]"
                  src={require("../../../../images/sort.png")}
                  alt=""
                />
                <span className="ml-1 text-[12px] text-black font-[500]">
                  Sort
                </span>
              </button>

              <button className="w-[auto] px-[20px] ml-[10px] rounded-full text-black bg-[#FFCC00] flex items-center justify-center h-[45px]">
                <span className="ml-1 text-[12px] font-[500]">
                  Resend verification link
                </span>
              </button>
              <button className="w-[auto] px-[20px]  ml-[10px] rounded-full text-black bg-[#FFCC00] flex items-center justify-center h-[45px]">
                <img
                  className="w-[24px] h-[24px]"
                  src={require("../../../../images/add-circle.png")}
                  alt=""
                />
                <span className="ml-1 text-[12px] font-[500]">
                  Edit patient profile
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <span>All files</span>
      <div className="overflow-x-auto border border-gray-200 rounded-[18px] shadow-sm mt-5 mb-4">
        <table className="w-full text-left bg-white">
          <thead className="bg-white">
            <tr>
              <th className="p-3 text-sm font-semibold text-gray-700">Name</th>
              <th className="p-3 text-sm font-semibold text-gray-700">
                Email Address
              </th>
              <th className="p-3 text-sm font-semibold text-gray-700">
                Last Transcript
              </th>
              <th className="p-3 text-sm font-semibold text-gray-700">
                View Transcriptions
              </th>
              <th className="p-3 text-sm font-semibold text-gray-700">
                Action
              </th>
            </tr>
          </thead>
        </table>
      </div>

      {/* Table Body */}
      <div className="overflow-x-auto border border-gray-200 rounded-[18px] shadow-sm">
        <table className="w-full text-left bg-white">
          <tbody>
            {data.map((user) => (
              <tr key={user.id} className="border-b hover:bg-gray-50">
                <td className="p-3 text-sm text-gray-600 w-[12%]">{user.id}</td>
                <td className="p-3 text-sm text-gray-600  w-[22.5%]">
                  {user.name}
                </td>
                <td className="p-3 text-sm text-gray-600  w-[24%]">
                  {user.email}
                </td>
                <td className="p-3 text-sm text-gray-600  w-[29%]">
                  {user.role}
                </td>
                <td className="p-3 text-sm text-gray-600">
                  <div className="flex flex-row h-[30px]">
                    <button className=" px-3 rounded-[15px] mr-[3px] bg-[#515A9C] hover:bg-blue-600">
                      <img
                        className="h-[15px] w-[15px] "
                        src={require("../../../../images/edit-01.png")}
                      />
                    </button>
                    <button className="px-3  rounded-[15px]   bg-[#2A6555] hover:bg-blue-600">
                      <img
                        className="h-[15px] w-[15px] "
                        src={require("../../../../images/translate.png")}
                      />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </DoctorDashboardLayout>
  );
};

export default PatientDetailsV2;
