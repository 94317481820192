import React from "react";
import QuestionImage from "../../images/question.svg";
import { FaArrowRightLong } from "react-icons/fa6";

const CheckoutFaq = () => {
  return (
    <div
      className="mb-[80px] w-full h-[219px] flex justify-center items-center  max-[750px]:h-[240px]"
      style={{
        background:
          "linear-gradient(270deg, rgba(42, 101, 85, 0.1) 0%, rgba(149, 164, 252, 0.1) 100%)",
      }}
    >
      <div className="flex items-center gap-4 font-semibold font-poppins max-[750px]:flex-col">
        <img src={QuestionImage} className="max-[750px]:w-[30%]" alt="" />
        <div className="text-[30px] leading-[45px] max-[750px]:text-[23px]  max-[750px]:leading-[32px]  ">
          <h3>Checkout some of our</h3>
          <div className="flex items-center gap-3 text-[#515A9C]  max-[750px]:flex-col  max-[750px]:items-start">
            <h3>Frequently asked Questions</h3>
            <FaArrowRightLong />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutFaq;
