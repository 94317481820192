import React, { useState } from "react";
import PatientDashboard from "../../../layout/patient/index";
import { MdKeyboardArrowRight } from "react-icons/md";
import ReportTopBorder from "../../../../images/reportBorder.png";
import MedLogo from "../../../../images/header-logo.png";
import HospitalLogo from "../../../../images/hospitalLogo.svg";
import { CiGlobe } from "react-icons/ci";
import { IoPrintOutline } from "react-icons/io5";
import { GrDocumentDownload } from "react-icons/gr";
import { IoPeopleOutline } from "react-icons/io5";
import { GrAnnounce } from "react-icons/gr";
import { AiOutlineDelete } from "react-icons/ai";
import { FaRegBookmark } from "react-icons/fa";
import EditCustomPopup from "../../../component/popups/EditCustomPopup";
import EditPopupContent from "../../../component/popups/EditPopupContent";

const ReportDetail = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  return (
    <PatientDashboard>
      <div className="flex items-center mb-5">
        <h2 className="text-3xl font-[500]">Reports</h2>
        <div className="flex items-center mt-[2px] ">
          <MdKeyboardArrowRight className=" text-[#B0B0B0]" size={25} />
          <MdKeyboardArrowRight size={32} className="-ml-[19px] text-grey" />
        </div>
        <h3 className="text-lg font-[500]">Ulceric Test result</h3>
      </div>

      <div className="w-full flex gap-3">
        <div className="w-[55%]">
          <div className="w-[90%] bg-white h-[842px] relative">
            <img src={ReportTopBorder} alt="" />

            <div className="p-[25px]">
              <div className="flex justify-between items-center mb-7 ">
                <img src={MedLogo} className="w-[150px] h-auto" alt="" />
                <img src={HospitalLogo} alt="" />
              </div>

              <div className="flex gap-4 items-center my-3">
                <p className="text-sm leadng-[21px]">Title:</p>
                <p className="text-sm font-semibold leading-[21px]">
                  Ulceric Test Results
                </p>
              </div>

              <div className="flex gap-4 items-center my-3">
                <p className="text-sm leadng-[21px]">Date and Time stamp:</p>
                <p className="text-sm font-semibold leading-[21px]">
                  10th October 2024 | 3:32:23 AM
                </p>
              </div>

              <div className=" my-5 border-b border-[#EFEBEB]"></div>

              <div className="flex w-full  my-6">
                <p className="text-sm leadng-[21px] w-[25%]">Medical Report:</p>
                <p className="text-sm  leading-[18px] w-[75%]">
                  Upon conducting diagnostic tests, including a urea breath test
                  and stool antigen test, the presence of Helicobacter pylori
                  was confirmed. An endoscopy was performed, revealing an ulcer
                  in the stomach lining. Biopsy samples taken during the
                  endoscopy confirmed the diagnosis of a peptic ulcer.
                </p>
              </div>

              <div className="flex w-full  my-6">
                <p className="text-sm leadng-[21px] w-[25%]">
                  Simplified Report:
                </p>
                <p className="text-sm  leading-[18px] w-[75%]">
                  Tests showed patient had a germ called Helicobacter pylori,
                  which is a type of bacteria that can infect the stomach. A
                  special camera test showed a sore in the stomach lining. The
                  tests confirmed it was a stomach ulcer.
                </p>
              </div>
            </div>

            <div className="absolute bottom-0 right-0 z-50 ">
              <img src={ReportTopBorder} className="rotate-180" alt="" />
            </div>
            <div className="absolute bottom-[35px] right-[25px] text-[#000]">
              <div className="flex items-center gap-1">
                <CiGlobe />
                <p className="text-sm">medinclude.com</p>
              </div>
            </div>

            <div className="absolute bottom-[65px] left-[25px] ">
              <div className="flex gap-4 items-center ">
                <p className="text-sm leadng-[21px]">Doctor in Charge:</p>
                <p className="text-sm font-semibold leading-[21px]">
                  Titobiloluwa O.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[45%]">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <div className="w-[50px] h-[50px] rounded-full bg-primary flex items-center justify-center ">
                <IoPrintOutline className="text-white text-[24px]" />
              </div>
              <p className="text-[16px] font-[500]">Print</p>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-[50px] h-[50px] rounded-full bg-primary flex items-center justify-center ">
                <GrDocumentDownload className="text-white text-[23px]" />
              </div>
              <p className="text-[16px] font-[500]">Save and Download</p>
            </div>
          </div>
          <div className="flex justify-between items-center mt-7">
            <div className="flex items-center gap-2">
              <div className="w-[50px] h-[50px] rounded-full bg-[#32ADE6] flex items-center justify-center ">
                <IoPeopleOutline className="text-white text-[24px]" />
              </div>
              <p className="text-[16px] font-[500]">Share Access</p>
            </div>
            <div className="flex items-center gap-2 mr-8">
              <div className="w-[50px] h-[50px] rounded-full bg-secondary flex items-center justify-center ">
                <GrAnnounce className="text-white text-[23px]" />
              </div>
              <p className="text-[16px] font-[500]">Read out Result</p>
            </div>
          </div>

          <div className="my-[50px]">
            <p className="text-lg font-[500]">Saved Notes</p>

            <div className="rounded-[20px] bg-white p-[35px] border-[#D7D7D7] border mt-3">
              <div className="flex">
                <div className="w-[80%]">
                  <p className="text-sm font-[500]">This is a note i have...</p>
                  <p className="text-sm mt-1">October 17th 2024, 8:30:31 am</p>
                </div>

                <div className="w-[20%] flex justify-end">
                  <AiOutlineDelete
                    className="text-[19px]"
                    onClick={() => setIsPopupOpen(true)}
                  />
                </div>
              </div>

              <div className="border-b border-[#D7D7D7] mt-1"></div>
            </div>
          </div>

          <div>
            <p className="text-lg font-[500]">Takes Notes</p>
            <textarea
              name=""
              id=""
              className="w-full min-h-[320px] max-h-[320px] h-[320px] overflow-y-auto rounded-[20px] mt-1 p-[35px] outline-none"
              placeholder="Enter Text"
            ></textarea>

            <div className="flex w-full justify-end mt-2">
              <button className="flex justify-center  items-center gap-2 bg-secondary w-[215px] h-[50px] rounded-full text-white font-semibold text-[16px]">
                <FaRegBookmark />
                Save Note
              </button>
            </div>
          </div>
        </div>
      </div>

      <EditCustomPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setIsDeletePopupOpen(false)}
      >
        <div className="w-[550px] bg-white rounded-[20px] border-[.7px] h-[333px] border-[#D7D7D7] p-[35px] flex items-center justify-center flex-col">
          <h3 className="text-[33px] font-[500]">Confirm Delete</h3>
          <p className="w-[316px] text-center text-[16px] leading-[21px] font-[500] text-grey">
            Deleted notes cannot be recovered. Do you want to delete?
          </p>

          <button className="w-[370px] bg-[#FF3B30] rounded-[10px] py-[10px] text-lg font-semibold mt-2 text-white">
            Delete Note
          </button>

          <button
            className="text-lg font-[500] border-b text-grey border-grey mt-1"
            onClick={() => setIsDeletePopupOpen(false)}
          >
            Cancel
          </button>
        </div>
      </EditCustomPopup>

      <EditCustomPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      >
        <EditPopupContent
          setIsPopupOpen={setIsPopupOpen}
          setIsDeletePopupOpen={setIsDeletePopupOpen}
        />
      </EditCustomPopup>
    </PatientDashboard>
  );
};

export default ReportDetail;
